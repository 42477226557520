<!-- 订单详情页面 -->
<template>
  <div class="pageF6Color">
    <headgobackVue :iCustomizdde="true" :myClick="backPage">
      <template #default>
        <span> {{ $fanyi(headStr) }}</span>
        <div
          class="headerButtonBox"
          v-if="
            ([0, 10, 20, 30, '0', '10', '20', '30'].includes(orderStatusNum) &&
              !is_y_editing) ||
            (orderStatusNum == 10 && orderStatusNoChange == '等待付款')
          "
        >
          <div v-if="userOperationMode == 'propose'">
            <van-popover
              v-model:show="showPopover"
              placement="bottom-end"
              class="headBtnGroup"
              :actions="
                [20, 30].includes(orderStatusNum) ? modifyTheOrder : actions
              "
              @select="onSelect"
            >
              <template #reference>
                <button>
                  {{ $fanyi("管理") }}
                </button>
              </template>
            </van-popover>
          </div>

          <button class="redFont" v-else @click="userOperationMode = 'propose'">
            {{ $fanyi("完成") }}
          </button>
        </div>
      </template>
    </headgobackVue>
    <loadingVue v-if="loading" />
    <temporarySavedOrderList v-if="['临时保存待提出'].includes(orderStatus)" />
    <otherOrdersListVue v-else />
    <div class="technicalSupport">
      {{ $fanyi("由1688提供部分技术服务⽀持") }}
    </div>
    <!-- 优惠券 -->
    <couponVue ref="couponVueRef" v-if="['等待付款'].includes(orderStatus)" />
    <!-- 添加商品 -->
    <addAdditionalProductVue ref="addAdditionalProductVueRef" />
    <!-- 订单明细 -->
    <orderDetailModal ref="orderDetailModalRef" />
    <!-- 用户操作 -->
    <userActionBarVue />
    <!-- 商品属性选择 -->
    <productAttributeSelectionVue ref="productAttributeSelectionVueRef" />
    <!-- 附加服务选择 -->
    <chooseAdditionalServiceVue ref="chooseAdditionalServiceVueRef" />
    <!-- 底部 -->
    <footBarVue />
    <!-- 弹窗 -->
    <myDialogVue ref="myDialog" :isprompt="true" />
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted, watch, provide } from "vue";
import myDialogVue from "@/components/Dialog";
import headgobackVue from "@/components/headGoBack";
import footBarVue from "../../components/footBar";
import loadingVue from "../../components/loading";
import productAttributeSelectionVue from "./compontnts/productAttributeSelection.vue";
import chooseAdditionalServiceVue from "./compontnts/chooseAdditionalService.vue";
import userActionBarVue from "./compontnts/userActionBar.vue";
import orderDetailModal from "./compontnts/orderDetailModal.vue";
import addAdditionalProductVue from "./compontnts/addAdditionalProduct.vue";
import temporarySavedOrderList from "./compontnts/temporarySavedOrderList.vue";
import otherOrdersListVue from "./compontnts/otherOrdersList.vue";
import couponVue from "./compontnts/coupon.vue";
const { proxy } = getCurrentInstance();
//------------------------ data -----------------------------------------
const headStr = ref("临时订单");
const allDetails = ref({}); //订单详情返回的所有数据
provide("allDetails", allDetails);
const oldallDetails = ref(); //用来对比的数据
const userOperationMode = ref("propose"); //操作栏模式 propose为提出订单模式,delete为删除模式
const token = ref(localStorage.getItem("token"));
const checked = ref(false); //全选
const loading = ref(true); //加载中
const type = ref(0); // 类型
const num = ref(0); //
const couponList = ref([]); //优惠券列表
const orderStatus = ref("报价中"); //订单状态
const orderStatusNum = ref(0); //订单状态数字
const orderStatusNoChange = ref("报价中"); //订单状态(只赋值一次)
const orderModification = ref(false); //是否是等待付款状态修改订单,配合orderStatus一起使用
const is_y_editing = ref(0); // 业务员是否在操作  1=在操作,需要不显示编辑按钮,0=不在操作
const isPay = ref(false); //用户是否已支付订单
const orderNumData = ref({
  allItemQuantity: 0, //所有商品番数
  itemPurchaseQuantity: 0, //所有商品购买数量
  totalItemPurchasePrice: 0, // 所有商品购买总价
  allAdditionalServiceFees: 0, // 所有附加服务费用
  allSelectedAdditionalServices: [], // 所有选中的附加服务
  allTransactionFees: 0, //所有手续费
  entireOrderAmount: 0, //整个订单的金额
  entireOrderShippingFee: 0, //整个订单的运费
  orderAllCommodityIdList: [], // 订单所有的商品id列表
}); //订单的数据合集
const totalPrice = ref(0); // 选中的商品总价
const lists = ref(null); // 数据
const idArr = ref([]); // 选中的商品ID
const additionalServiceList = ref([]); //附加服务列表
const shippingMethodList = ref([]); //物流方式列表
const showPopover = ref(false); //是否打开头部菜单
const logistics_id = ref(""); //物流方式id
const logistics = ref({}); //物流方式数据
const client_remark = ref(); //整个订单客户备注
const hasUsedCoupon = ref(false); //是否已经使用优惠券
const usedCouponInfo = ref({}); //已使用优惠券的信息
const isProposedAgain = ref(proxy.$route.query.isProposedAgain || false); //是否是再次提出
const exchange_rate = ref(proxy.$store.state.exchangeRate);
const otherPriceData = ref({});

const backPage = () => {
  // proxy.$router.go(-1);
  if (isProposedAgain.value) {
    proxy.$router.go(0);
  } else {
    proxy.$router.go(-1);
  }
};

// 再次提出订单
const proposeAgain = () => {
  loading.value = true;

  proxy.$api
    .ordercopyOrder({
      order_sn: proxy.$route.query.order_sn,
      order_detail_id: idArr.value,
    })
    .then((res) => {
      loading.value = false;
      ////console.log('事件名',res)
      if (res.code != 0) return proxy.$message.error(proxy.$fanyi(res.msg));
      if (res.data == []) return proxy.$message.error(proxy.$fanyi("操作失败"));
      // 删除没有选中的商品
      for (let i = 0; i < lists.value.length; i++) {
        let shopItem = lists.value[i];
        for (let j = 0; j < lists.value[i].goods.length; j++) {
          let goodsItem = lists.value[i].goods[j];
          if (idArr.value.indexOf(goodsItem.id) == -1) {
            shopItem.goods.splice(j, 1);
            j--;
          }
        }
        if (shopItem.goods.length == 0) {
          lists.value.splice(i, 1);
          i--;
        }
      }
      // 重新计算
      commodityTotal();
      //接下来的操作
      // return console.log(res);
      orderStatus.value = "临时保存待提出";
      orderStatusNum.value = "10";
      isProposedAgain.value = true;
      allDetails.value.order_sn = res.data.order_sn;
      otherPriceData.value = {};
    });
};

// 打开追加商品
const openAddAdditionalProductModal = () => {
  proxy.$refs.addAdditionalProductVueRef.open({
    overlook_cart_ids: orderNumData.value.orderAllCommodityIdList,
  });
};
// 跳到修改订单状态
const modifyOrderEvent = () => {
  orderStatus.value = "临时保存待提出";
  orderStatusNum.value = 10;
  orderModification.value = true;
  oldallDetails.value = JSON.parse(JSON.stringify(allDetails.value));
  // oldallDetails.value.order_detail.value.forEach((item) => {
  //   // item-单个店铺数据
  //   // 假如有附加服务并且里面有数据
  //   item.goods.forEach((goodsItem) => {
  //     for (let i = 0; i < goodsItem.option; i++) {
  //       if (!goodsItem.option[i].checked) {
  //         goodsItem.option.splice(i, 1);
  //         i--;
  //       }
  //     }
  //   });
  // });
};
// 临时保存状态头部管理菜单按钮列表
const actions = [
  {
    text: proxy.$fanyi("追加商品"),
    methods: openAddAdditionalProductModal,
    icon: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202305/64549e56e91e5.png",
  },
  {
    text: proxy.$fanyi("删除商品"),
    value: "delete",
    icon: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202305/64549ea29a596.png",
  },
];
// 临时保存状态头部管理菜单按钮列表
const modifyTheOrder = ref([
  { text: proxy.$fanyi("修改订单"), methods: modifyOrderEvent },
]);
// //------------------------ pageLoad -------------------------------------
onMounted(() => {
  getData();
  getShippingMethodList();
});
// //------------------------ methods --------------------------------------
// 取消修改订单
const unmodify = () => {
  getData();
};
// 获取单个商品规格数据并且执行预存的操作 data传递{item,omethods}
const getProductSpecificationData = (odata) => {
  let execute = () => {
    if (odata.omethods) {
      odata.omethods();
    }
  };
  if (!!odata.item.goodsInventory && !!odata.item.specification) {
    execute();
  }
  if (!odata.item.goodsInventory || !odata.item.specification) {
    proxy.$api
      .getGoodsAttribute({
        shop_type: odata.item.from_platform,
        goods_id: odata.item.goods_id,
      })
      .then((GoodsAttribute) => {
        if (GoodsAttribute.code != 0) {
          return execute();
        }
        GoodsAttribute.data.forEach((item, index) => {
          // odata.item---单个商品所有数据
          odata.item.goodsInventory = JSON.parse(
            JSON.stringify(item.goodsInventory)
          );
          odata.item.specification = JSON.parse(
            JSON.stringify(item.specification)
          );
          // 为specification(商品属性列表)设置chiose(用户选中的属性)以备用
          odata.item.detail.forEach((detailItem, detailIndex) => {
            odata.item.specification[detailIndex].chiose =
              odata.item.specification[detailIndex].valueC.findIndex((item) => {
                return item.name == detailItem.value;
              });
          });
          // 设置默认的价格区间
          odata.item.priceRange = odata.item.goodsInventory.find(
            (InventoryItem) => {
              // InventoryItem-单个商品里的单条sku对象
              // 拼接出匹配sku的字符串
              let searchstr = odata.item.detail
                .map((obj) => obj.value)
                .join("㊖㊎");
              return (
                InventoryItem.keyC == searchstr ||
                InventoryItem.keyT == searchstr
              );
            }
          ).valueC;
          // calculateSingleProductPrice(odata.item);
          // 处理好数据之后如果有需要执行的事件就执行事件
          execute();
        });
      });
  }
};
// 再次提出订单
const reintroduce = () => {
  // return console.log(
  //   oldallDetails.value == JSON.stringify(allDetails.value),
  //   oldallDetails.value,
  //   JSON.stringify(allDetails.value)
  // );

  // 将数据拼接并重新提交
  let newList = getAllProducts();
  if (newList.length == 0) {
    return proxy.$refs.myDialog.open({
      text: proxy.$fanyi(
        "当前订单所有商品已删除，重新提出订单会直接取消，请确认"
      ),
      successMethods: () => {
        loading.value = true;
        editApi((res) => {
          loading.value = false;
          if (res.code != 0)
            return proxy.$message.error(proxy.$fanyi("操作失败"));
          proxy.$message.success(proxy.$fanyi("操作成功"));
          setTimeout(() => {
            proxy.$fun.routerToPage("/cart");
          }, 1000);
        });
      },
    });
  } else if (newList.length > 100) {
    return proxy.$message.warning(proxy.$fanyi("一个订单最多添加100个商品"));
  }
  editApi();
};
// 提交修改请求事件
const editApi = (methods) => {
  let newList = getAllProducts();
  // return;
  let dataList = [];
  let str = "";
  let sku_id = "";
  let spec_id = "";
  newList.forEach((item) => {
    str = "";
    item.detail.forEach((ditem) => {
      str += ditem.value + "㊖㊎";
    });
    str = str.substr(0, str.length - 2);
    let optionList = [];
    item.option.forEach((optionItem) => {
      if (Number(optionItem.num) > 0 && optionItem.checked === true) {
        optionItem.total = undefined;
        optionList.push(optionItem);
      }
    });
    let odata = {
      id: item.id,
      goods_id: item.goods_id,
      goods_title: item.goods_title,
      price:
        (item.is_now_data && orderStatusNoChange.value != "报价中"
          ? item.is_now_data.price
          : "") ||
        item.univalence ||
        item.price, //假如是重新提交就根据orderStatusNoChange判断等待付款状态的就拿is_now_data里的,否则先拿univalence(匹配数量的价格),如果没有univalence就拿price
      num: item.num, //因为之前item.is_now_data.num已经赋给item.num了,所以不需要判断
      pic: item.pic,
      detail: JSON.stringify(item.detail),
      sku_id: item.sku_id,
      spec_id: item.spec_id,
      shop_id: item.shop_id,
      shop_name: item.shop_name,
      order_detail_tag: undefined,
      option: optionList.length > 0 ? JSON.stringify(optionList) : "[]",
      from_platform: item.from_platform,
      client_remark: item.client_remark,
    };
    if (item.preview_type == "cart") {
      let cart_id = item.id;
      odata.cart_id = cart_id;
      delete odata.id;
    } else if (item.preview_type == "productStore") {
      delete odata.id;
    }
    if (!odata.id) {
      delete odata.id;
    }
    if (!odata.cart_id) {
      delete odata.cart_id;
    }
    dataList.push(odata);
  });
  dataList.forEach((item) => {
    for (let i in item) {
      // console.log(typeof item[i], i);
    }
  });
  let datas = {
    order_file: undefined,
    create_type: "send",
    order_sn: allDetails.value.order_sn,
    logistics_id: logistics_id.value,
    client_remark: client_remark.value,
    order_detail: dataList,
    type_translate: 2,
  };
  // return console.log(datas, newList);
  proxy.$api.orderEdit(datas).then((res) => {
    if (methods) {
      methods(res);
      return;
    }
    ////console.log('事件名',res)
    if (res.code != 0) return proxy.$message.error(proxy.$fanyi(res.msg));
    proxy.$message.success(
      proxy.$fanyi("订单已提交。")
    );
    setTimeout(() => {
      proxy.$fun.routerToPage("/cart");
    }, 1000);
  });
};
// 打开使用优惠券弹窗
const openUseCouponModal = () => {
  proxy.$refs.couponVueRef.open();
};
// 点击管理弹出框事件
const onSelect = (action) => {
  if (action.value) {
    userOperationMode.value = action.value;
  }
  if (action.methods) {
    action.methods();
  }
};
// 获取所有物流方式
const getShippingMethodList = () => {
  proxy.$api.internationalLogisticsTransportation().then((res) => {
    if (res.code != 0) return;
    if (res.data.length == 0) return false;
    res.data.forEach((dataItem) => {
      dataItem.text = dataItem.name;
      dataItem.value = dataItem.id;
    });
    shippingMethodList.value = res.data;
    // 设置默认物流方式
    if (!logistics_id.value) {
      logistics_id.value = res.data[0].id;
    }
  });
};
// 将附加服务添加到各个商品里
const addAdditionalServicesToEachProduct = () => {
  lists.value.forEach((item) => {
    // item-单个店铺数据
    // 假如有附加服务并且里面有数据
    item.goods.forEach((goodsItem) => {
      let optionList = JSON.parse(JSON.stringify(additionalServiceList.value));
      if (
        goodsItem.option &&
        typeof goodsItem.option == "object" &&
        goodsItem.option.length
      ) {
        // goodsItem-单个商品
        // 将商品数据里没有的附加服务添加进去;
        let newArr = optionList.filter(
          (obj) =>
            !goodsItem.option.some((newArritem) => newArritem.id === obj.id)
        );
        goodsItem.option = [...goodsItem.option, ...newArr];
        goodsItem.option.sort(function (a, b) {
          return (a.sort || 0) - (b.sort || 0);
        });
      } else {
        goodsItem.option = optionList;
      }
    });
  });
};
// 获取附加服务列表并添加到各个商品
const getAdditionalServiceList = () => {
  proxy.$api.optionList().then((res) => {
    additionalServiceList.value = res.data;
    addAdditionalServicesToEachProduct();
  });
  commodityTotal();
};
// 打开订单明细弹窗
const openOrderDetailModal = () => {
  proxy.$refs.orderDetailModalRef.open();
};
// 打开附加服务选择弹窗
const openAdditionalService = (listItem, isReadOnly) => {
  // listItem-单个商品所有数据
  proxy.$refs.chooseAdditionalServiceVueRef.open(
    listItem,
    () => {
      commodityTotal();
    },
    isReadOnly
  );
};
// 打开选择商品属性弹窗
const openDetailSheet = (listItem, type) => {
  // listItem-单个商品所有数据
  proxy.$refs.productAttributeSelectionVueRef.open(
    listItem,
    () => {
      priceRangeMatching(listItem); //修改成功后匹配商品的价格区间
      editSingleProductAttribute(listItem); //修改成功后事件
    },
    type
  );
};
// 根据用户选择商品规格匹配单个商品的价格区间
const priceRangeMatching = (listItem) => {
  // listItem-单个商品所有数据
  // 设置默认的价格区间
  // 拼接出匹配sku的字符串
  let searchstr = listItem.detail.map((obj) => obj.value).join("㊖㊎");
  let pricerange = listItem.goodsInventory.find((InventoryItem) => {
    // InventoryItem-单个商品里的单条sku对象
    return InventoryItem.keyC == searchstr || InventoryItem.keyT == searchstr;
  });
  if (pricerange) {
    listItem.priceRange = pricerange.valueC;
  }
};
// 递归查找数组里符合条件的对象(只会寻找key是goods的子数组)(传参:数组,要匹配的key名字,要匹配的value)
const findAllObjectsWithPropertyName = (array, propertyName, propertyValue) => {
  return array.flatMap((obj) => {
    if (obj[propertyName] === propertyValue) {
      return obj;
    } else if (obj.goods) {
      return findAllObjectsWithPropertyName(
        obj.goods,
        propertyName,
        propertyValue
      );
    } else {
      return [];
    }
  });
};
// 获取数据
const getData = () => {
  loading.value = true;
  // 根据传参调用对应的接口
  if (proxy.$route.query.id) {
    proxy.$api.orderPreview({ cart_ids: proxy.$route.query.id }).then((res) => {
      loading.value = false;
      // 设置默认物流方式;
      // logistics_id.value = 2;
      headStr.value = proxy.$fanyi("临时订单");
      orderStatus.value = "临时保存待提出";
      orderStatusNoChange.value = "临时保存待提出";
      if (res.code != 0) {
        lists.value = [];
        return false;
      }
      allDetails.value = res.data;
      lists.value = res.data.order_data;
      tidyData();
    });
  } else {
    proxy.$api
      .orderDetail({ order_sn: proxy.$route.query.order_sn })
      .then((res) => {
        if (res.code != 0 || !res.data) {
          loading.value = false;
          return proxy.$message.error(proxy.$fanyi(res.msg));
        }
        allDetails.value = res.data;
        is_y_editing.value = res.data.is_y_editing || 0;
        // 提出问题产品之后价格会对不上,所以付款之后的状态显示接口返回的价格数据
        // 当订单的状态走到等待付款之后就使用接口返回的价格
        if (res.data.status >= 40) {
          otherPriceData.value = res.data.order_amount;
        }
        if (res.data.exchange_rate > 0) {
          exchange_rate.value = res.data.exchange_rate;
        }

        lists.value = res.data.order_detail;
        logistics_id.value =
          res.data.logistics_id ||
          (res.data.logistics ? res.data.logistics.id : "") ||
          2; //接口临时保存的订订单返回的还是logistics_id,正式提出的返回的是logistics
        if (res.data.logistics) {
          logistics.value = res.data.logistics;
        }
        // 订单详情中已取消订单状态并且客户已付款的情况下需要显示客户付的实际运费,因为运费是不退的,所以需要isPay这个参数
        isPay.value = res.data.is_pay || false;
        client_remark.value = res.data.client_remark;
        orderStatusNoChange.value = proxy.$fun.deepClone(res.data.status_name);
        orderStatus.value = res.data.status_name;
        orderStatusNum.value = res.data.status;
        //分批付款状态 30:第一次付款 40:第一次付款待确认 50:正在购买 60:待支付尾款
        if (res.data.status == 40) {
          if (res.data.part_pay_status == 0) {
            // status == 40代表已付款待确认,接口返回的是等待付款,因为需要兼容外面的列表,所以需要手动来写
            orderStatus.value = "已付款待确认";
            orderStatusNoChange.value = "已付款待确认";
          } else {
            // orderStatusNum.value = 30;
            orderStatus.value = "等待付款";
            orderStatusNoChange.value = "等待付款";
          }
        }
        if (res.data.status == 60) {
          orderStatusNum.value = 30;
          orderStatus.value = "等待付款";
          orderStatusNoChange.value = "等待付款";
        }

        if (
          !![30, 40, 50, 60].includes(res.data.status) &&
          res.data.part_pay_status > 0 &&
          res.data.part_pay_status_name != "付款完成"
        ) {
          proxy.$refs.myDialog.open({
            text: proxy.$fanyi(
              "分批付款的订单，请使用电脑打开网站进行付款，手机浏览器，APP暂时无法进行支付，请谅解。"
            ),
            title: proxy.$fanyi("提示"),
            successBtn: proxy.$fanyi("确认"),
            isprompt: true,
          });
        }
        // 假如是再次提出页面就将订单状态位改为临时保存
        if (orderStatus.value == "临时保存待提出") {
          headStr.value = proxy.$fanyi("临时订单");
        } else {
          headStr.value = proxy.$fanyi("订单详情");
        }

        tidyData();
      });
  }
};
// 整理数据 (获取数据时调用)
const tidyData = () => {
  //   获取所有商品的id和type
  let arr = [];
  lists.value.forEach((item) => {
    item.goods.forEach((goodsItem) => {
      if (typeof goodsItem.detail === "string") {
        goodsItem.detail = JSON.parse(goodsItem.detail);
      }
      // 如果是临时保存的订单就把is_client_send_data里的数据放到单个商品数据里,后面使用这些数据计算
      // 2023/4/13 报价前用 is_client_send_data 里面的 报价后用 is_now_data 里面的
      if (
        proxy.$route.query.order_sn &&
        ["临时保存待提出", "报价中", "已取消"].includes(orderStatus.value)
      ) {
        for (let i in goodsItem.is_client_send_data) {
          goodsItem[i] = goodsItem.is_client_send_data[i];
        }
      } else {
        for (let i in goodsItem.is_now_data) {
          goodsItem[i] = goodsItem.is_now_data[i];
        }
      }
    });
  });
  loading.value = false;
  if (proxy.$route.name == "excelOrderPreview") {
    proposeAgain();
  }
  dataChuShiHua();
};

// 初始化商品的一些数据
const dataChuShiHua = () => {
  lists.value.forEach((item) => {
    item.type = item.goods[0].type; // 店铺类别
    item.goods.forEach((item1) => {
      // 商品总数
      item1.checked = false; // 商品选择
      item1.univalence = 0; // 显示单价
      item1.total = 0;
      // item1.price_range = JSON.parse(item1.price); // 字符串转换组数
    });
  });
  getAdditionalServiceList(); //获取附加服务列表并添加到各个商品
  arrangeProducts(); //为商品排列番号
  subtotal(); // 判断每一番商品使用哪个价格 并计算单个商品总价
  commodityTotal(); // 统计数据(数据获取和变化时调用)
  loading.value = false;
};
// 为商品排列番号和初始化选中状态
const arrangeProducts = () => {
  let sort = 1;
  lists.value.forEach((item) => {
    item.checked = false;
    item.goods.forEach((item1) => {
      item1.sort = sort++;
      item1.checked = false;
    });
  });
};
// 编辑单个商品属性
const editSingleProductAttribute = (listItem) => {
  // 计算金钱
  subtotal();
  commodityTotal();
};
// 判断每一番商品使用哪个价格 并计算单个商品总价
const subtotal = () => {
  lists.value.forEach((item) => {
    item.goods.forEach((listItem) => {
      listItem.univalence = 0;
      // 这里如果有价格区间并且没有报价数据就从价格区间里面取,如果有报价数据或者没有价格区间就把univalence设为0,因为price在前面已经判断获取过了
      if (
        listItem.is_now_data &&
        listItem.is_now_data.price &&
        listItem.is_now_data.price != 0
      ) {
      } else if (listItem.priceRange) {
        for (let i = listItem.priceRange.length - 1; i >= 0; i--) {
          // priceItem-单个商品里的单条价格数据
          const priceItem = listItem.priceRange[i];
          if (priceItem.startQuantity <= listItem.num) {
            listItem.univalence = priceItem.price;
            break;
          }
        }
      }

      listItem.total =
        listItem.num *
        (!!listItem.univalence ? listItem.univalence : listItem.price);
    });
  });
};

// 单选
const oddChoice = (i) => {
  let flag = lists.value[i].goods.every((item) => item.checked == true);
  if (flag) {
    lists.value[i].checked = true;
  } else {
    lists.value[i].checked = false;
  }
  isCheckedAll();
  commodityTotal();

  //   flag ? (lists.value[i].checked = true) : (lists.value[i].checked = false);
};
// 店铺全选
const shopAll = (i) => {
  if (lists.value[i].checked) {
    lists.value[i].goods.forEach((item) => {
      item.checked = true;
    });
  } else {
    lists.value[i].goods.forEach((item) => {
      item.checked = false;
    });
  }

  isCheckedAll();
  commodityTotal();
};
// 全选
const allChoice = () => {
  if (checked.value) {
    lists.value.forEach((item, index) => {
      item.checked = true;
      shopAll(index);
    });
  } else {
    lists.value.forEach((item, index) => {
      item.checked = false;
      shopAll(index);
    });
  }
  commodityTotal();
};
// 是否全选中
const isCheckedAll = () => {
  var flag = lists.value.every((item, index) => {
    return item.checked == true;
  });
  if (flag == true) {
    checked.value = true;
  } else {
    checked.value = false;
  }
};
// 统计数据(数据获取和变化时调用)
const commodityTotal = () => {
  // 初始化数据
  totalPrice.value = 0; //选中的商品总价
  orderNumData.value.allItemQuantity = 0; //商品所有番数
  orderNumData.value.itemPurchaseQuantity = 0; //商品所有购买数量
  orderNumData.value.totalItemPurchasePrice = 0; //商品所有购买总价
  orderNumData.value.allSelectedAdditionalServices = []; //所有选中的附加服务
  orderNumData.value.allTransactionFees = 0; //所有商品的手续费
  orderNumData.value.entireOrderAmount = 0; // 整个订单的金额
  orderNumData.value.entireOrderShippingFee = 0; // 整个单子的运费
  orderNumData.value.orderAllCommodityIdList = []; // 订单所有的商品id列表
  orderNumData.value.allAdditionalServiceFees = 0; //所有附加服务费用
  idArr.value = [];
  lists.value.forEach((item, i) => {
    // item-单个店铺
    item.goods.forEach((listItem, j) => {
      // listItem-单个商品
      if (listItem.checked) {
        totalPrice.value += listItem.total - 0;
        idArr.value.push(listItem.id);
      }
      orderNumData.value.orderAllCommodityIdList.push(listItem.id);
      orderNumData.value.allItemQuantity++;
      orderNumData.value.itemPurchaseQuantity += listItem.num;
      // listItem.total是subtotal()计算出来的
      orderNumData.value.totalItemPurchasePrice += listItem.total;
      // 计算整个订单的运费
      orderNumData.value.entireOrderShippingFee += Number(
        listItem.freight || 0
      );
      // 数据里的手续费比例没有更改,只是订单明细弹窗里的手续费显示更改了
      //统计所有商品的手续费
      orderNumData.value.allTransactionFees +=
        listItem.total *
        (() => {
          if (hasUsedCoupon.value) {
            return 0;
          } else {
            return Number(listItem.service_rate) || 0;
          }
        })();

      // 计算总附加服务费用并用户选择的统计附加服务列表
      orderNumData.value.allAdditionalServiceFees += (() => {
        // additionalServiceFees-单个商品的附加服务费用
        let additionalServiceFees = 0;
        if (listItem.option && typeof listItem.option == "object") {
          // 循环单个商品的所有附加服务数据
          listItem.option.forEach((optionItem) => {
            //     // optionItem-单个商品的单条附加服务
            // 假如附加服务选中了
            if (optionItem.checked) {
              // optionItem.price_type-费用类型(由接口返回) 0-单价 1-百分比
              if (!optionItem.price_type) {
                // 如果费用类型是单价 单个商品单条附加服务的总价等于数量乘以价格
                // 计算整个订单的附加服务总价
                additionalServiceFees += optionItem.num * optionItem.price;
                // 计算单个商品的单条附加服务总价
                optionItem.total = proxy.$fun.ceil(
                  optionItem.num * optionItem.price,
                  4
                );
              } else {
                // 如果费用类型是百分比 单个商品单条附加服务的总价等于单个商品的总价乘以百分比
                // 2023/5/25 百分比的附加服务选项做成附加服务选择数量*商品单价*百分比
                // 计算整个订单的附加服务总价
                // console.log(
                //   proxy.$fun.ceil(
                //     listItem.price * optionItem.num * (optionItem.price / 100)
                //   )
                // );
                additionalServiceFees += proxy.$fun.ceil(
                  listItem.price * optionItem.num * (optionItem.price / 100)
                );
                // 计算单个商品的单条附加服务总价
                optionItem.total = proxy.$fun.ceil(
                  listItem.price * optionItem.num * (optionItem.price / 100)
                );
              }
              // 将各个商品的附加服务统计整合到orderNumData.value.allSelectedAdditionalServices里
              // 假如没有添加到统计的数组里面就添加进去,否则判断价格类型就加一下数量或者加百分比价格
              //***************
              // 查找整个订单的附加服务列表里面是否已经有optionItem这一条附加服务了
              let findData =
                orderNumData.value.allSelectedAdditionalServices.findIndex(
                  (ServicesItem) => {
                    // ServicesItem- orderNumData.value.allSelectedAdditionalServices这个数组里的单条数据
                    return ServicesItem.sort == optionItem.sort;
                  }
                );
              // 如果总附加服务列表里已经有了就在已有的附加服务上相加，否则添加到总附加服务列表里
              if (findData == -1) {
                // 把没有的附加服务加入整个订单的附加服务列表里面
                // 这里必须要深拷贝,否则会影响到商品列表的附加服务数据
                orderNumData.value.allSelectedAdditionalServices.push(
                  JSON.parse(JSON.stringify(optionItem))
                );
              } else {
                // 判断费用类型,根据费用类型判断相加
                if (!optionItem.price_type) {
                  // 如果费用类型是单价:把整个订单的附加服务列表已有的数量和optionItem里的数量相加
                  orderNumData.value.allSelectedAdditionalServices[
                    findData
                  ].num += optionItem.num;
                } else {
                  // 如果费用类型是百分比:把整个订单的附加服务列表已有的价格和optionItem里的总价和数量相加(总价在上面算好了)
                  orderNumData.value.allSelectedAdditionalServices[
                    findData
                  ].total += optionItem.total;
                  orderNumData.value.allSelectedAdditionalServices[
                    findData
                  ].num += optionItem.num;
                }
              }
            }
          });
        }
        return proxy.$fun.ceil(additionalServiceFees);
      })(); // 计算所有的附加服务费用
    });
  });
  orderNumData.value.allAdditionalServiceFees = proxy.$fun.ceil(
    orderNumData.value.allAdditionalServiceFees
  );
  // 计算整个订单总金额
  orderNumData.value.entireOrderAmount = proxy.$fun.ceil(
    orderNumData.value.allTransactionFees +
      orderNumData.value.allAdditionalServiceFees +
      Number(allDetails.value.other_price || 0) +
      (orderNumData.value.entireOrderShippingFee || 0) +
      orderNumData.value.totalItemPurchasePrice
  );

  // console.log(
  //   orderNumData.value.allTransactionFees,
  //   orderNumData.value.allAdditionalServiceFees,
  //   orderNumData.value.totalItemPurchasePrice
  // );
};
// 删除
const delCart = (id, shopIndex, goodsIndex) => {
  let datas = {
    ids: "",
  };
  let msg = proxy.$fanyi("是否将选中的商品移除订单详情");
  // 单个商品删除
  if (id) {
    datas.ids = id;
  } else {
    // 多个商品删除
    if (idArr.value == false)
      return proxy.$message.error(proxy.$fanyi("未选择操作的商品"));
    datas.ids = idArr.value.join(",");
    msg = proxy.$fanyi("是否将选中的商品移除订单详情");
  }
  proxy.$refs.myDialog.open({
    text: msg,
    successBtn: proxy.$fanyi("删除"),
    cancelBtn: proxy.$fanyi("取消"),
    successMethods: () => {
      // on confirm
      // 确认按钮事件
      proxy.$message.success(proxy.$fanyi("移除成功"));
      if (id) {
        lists.value[shopIndex].goods.splice(goodsIndex, 1);
        if (lists.value[shopIndex].goods == 0) {
          lists.value.splice(shopIndex, 1);
        }
      } else {
        // 将订单详情选中的选项删除
        for (let i = 0; i < lists.value.length; i++) {
          let shopItem = lists.value[i];
          for (let j = 0; j < lists.value[i].goods.length; j++) {
            let goodsItem = lists.value[i].goods[j];
            if (idArr.value.indexOf(goodsItem.id) != -1) {
              shopItem.goods.splice(j, 1);
              j--;
            }
          }
          if (shopItem.goods.length == 0) {
            lists.value.splice(i, 1);
            i--;
          }
        }
      }
      // 获取总价
      commodityTotal();
    },
  });
};

// 将接受到的数据追加到订单详情
const appendCommodity = (data, type) => {
  for (let i = 0; i < data.length; i++) {
    // shopItem添加商品组件传入的单个店铺数据
    let shopItem = data[i];
    for (let j in shopItem.goods) {
      shopItem.goods[j].preview_type = type;
      if (!shopItem.goods[j].num) {
        shopItem.goods[j].num = 1;
        shopItem.goods[j].total = proxy.$fun.ceil(
          shopItem.price * shopItem.num
        );
      }
      // console.log(shopItem.goods[j], "添加数据");
    }

    lists.value.push(shopItem);
  }
  arrangeProducts();
  subtotal();
  addAdditionalServicesToEachProduct();
  commodityTotal();
};
// 提取所有商品
const getAllProducts = () => {
  const objects = lists.value.reduce((acc, cur) => [...acc, ...cur.goods], []);
  return objects;
};
// 创建订单
const createOrder = (type) => {
  let fetchType = "";
  switch (type) {
    case "tempSave":
      fetchType = "save";
      break;
    case "submit":
      fetchType = "send";
      break;
    default:
      break;
  }
  let newList = getAllProducts();
  // 2023/8/4 修改成当提出订单时不限制里面有没有商品,没有商品时提出删除订单
  // if (newList.length == 0) {
  //   return proxy.$message.warning(proxy.$fanyi("请至少添加一个商品"));
  // } else
  if (newList.length > 100) {
    return proxy.$message.warning(proxy.$fanyi("一个订单最多添加100个商品"));
  }
  if (type == "submit") {
    proxy.$fun.statisticsNumberOfOrders(
      JSON.parse(JSON.stringify(newList)),
      "send"
    );
  }
  let dataList = [];
  let str = "";
  let sku_id = "";
  let spec_id = "";
  newList.forEach((item) => {
    str = "";
    item.detail.forEach((item) => {
      str += item.value + "㊖㊎";
    });
    str = str.substr(0, str.length - 2);
    // if (item.goodsInventory) {
    //   item.goodsInventory.forEach((goodsInventoryItem) => {
    //     if (
    //       str === goodsInventoryItem.keyC ||
    //       str === goodsInventoryItem.keyT
    //     ) {
    //       sku_id = goodsInventoryItem.valueC[0].skuId;
    //       spec_id = goodsInventoryItem.valueC[0].specId;
    //     }
    //   });
    // }

    let optionList = [];
    item.option.forEach((optionItem) => {
      if (Number(optionItem.num) > 0 && optionItem.checked === true) {
        optionItem.total = undefined;
        optionList.push(optionItem);
      }
    });
    let odata = {
      cart_id: item.cart_id,
      goods_id: item.goods_id,
      goods_title: item.goods_title,
      price:
        orderStatus.value === "临时保存待提出" &&
        proxy.$route.query.id == undefined
          ? item.is_client_send_data
            ? item.is_client_send_data.price
            : item.price //这里因为临时保存的追加商品(orderStatus.value === "临时保存待提出" && proxy.$route.query.id == undefined)也等于true,所以需要加item.price
          : item.univalence || item.price, //假如是重新提交就拿is_client_send_data里的,否则先拿univalence(匹配数量的价格),如果没有univalence就拿price
      num: item.num,
      pic: item.pic,
      detail: JSON.stringify(item.detail),
      sku_id: item.sku_id,
      spec_id: item.spec_id,
      shop_id: item.shop_id,
      shop_name: item.shop_name,
      order_detail_tag: undefined,
      option: optionList.length > 0 ? JSON.stringify(optionList) : "[]",
      from_platform: item.from_platform,
      client_remark: item.client_remark,
      trace: item.trace,
    };
    if (item.preview_type == "cart") {
      let cart_id = item.id;
      odata.cart_id = cart_id;
    }
    if (!odata.cart_id) {
      delete odata.cart_id;
    }

    dataList.push(odata);
  });
  let datas = {
    order_file: undefined,
    create_type: fetchType,
    order_sn: allDetails.value.order_sn,
    logistics_id: logistics_id.value,
    client_remark: client_remark.value,
    order_detail: dataList,
    type_translate: 2,
  };
  proxy.$api.orderCreate(datas).then((res) => {
    if (res.code != 0) return proxy.$message.error(proxy.$fanyi(res.msg));
    // 假如点击的是临时保存按钮
    if (type == "tempSave") {
      proxy.$toast(proxy.$fanyi("订单已保存成功，可前往会员中心-订单列表查看"));
      setTimeout(() => {
        proxy.$fun.routerToPage("/user/order?type=temporary");
      }, 1000);
    }
    // 假如点击的是提出按钮
    if (type == "submit") {
      proxy.$toast(proxy.$fanyi("订单已提交。"));
      setTimeout(() => {
        proxy.$fun.routerToPage("/user/order?type=offer");
      }, 1000);
    }
  });
};

// 保存阿里巴巴的统计数据 (将需要统计的数据保存到本地,支付页面支付的时候从本地获取并上传统计数据)
const save_blSum_data = (newList) => {
  let paySubmitGoodsList = [];
  newList.forEach((item, index) => {
    // console.log(item);
    paySubmitGoodsList.push({
      trace: item.trace,
      goods_id: item.goods_id,
      goods_title: item.goods_title,
    });
  });
  localStorage.setItem(
    "paySubmitGoodsList",
    JSON.stringify(paySubmitGoodsList)
  );
};

// 前往付款
const proceedToPayment = () => {
  // 如果业务报的商品数量有部分是0就弹提示"有几番为0的前台操作需要提示语"当前订单xx番,xx番k库存为0，确认
  // 后以上商品将取消购买"
  // 整个单子为0的需要提示语“因为库存不足，单子确认后将取消”
  let newList = getAllProducts();
  let str = "";
  let count = 0;
  newList.forEach((item, index) => {
    if (item.is_now_data.num == 0 && item.status != -2) {
      count++;
      str += item.sorting + "、";
    }
  });
  str = str.substr(0, str.length - 1);
  str = str + "番";
  if (count === 0) {
    save_blSum_data(newList);
    proxy.$fun.routerToPage(
      `/payment?order_sn=${proxy.$route.query.order_sn}&discounts_id=${
        usedCouponInfo.value.id || ""
      }`
    );
  } else if (count !== newList.length) {
    proxy.$refs.myDialog.open({
      text: `現在の注文書：${str}の在庫が0です。確認後、上記の商品は購入がキャンセルされます。`,
      successBtn: proxy.$fanyi("确定"),
      cancelBtn: proxy.$fanyi("取消"),
      successMethods: () => {
        proxy.$api
          .updateOrderStatus({
            order_sn: proxy.$route.query.order_sn,
          })
          .then((res) => {
            if (res.code != 0)
              return proxy.$message.error(proxy.$fanyi(res.msg));
            proxy.$message.success(proxy.$fanyi(res.msg));
            setTimeout(() => {
              save_blSum_data(newList);
              proxy.$fun.routerToPage(
                `/payment?order_sn=${
                  proxy.$route.query.order_sn
                }&discounts_id=${usedCouponInfo.value.id || ""}`
              );
            }, 1000);
          });
      },
    });
  } else if (count === newList.length) {
    proxy.$refs.myDialog.open({
      text: `ご注文の商品すべて在庫不足のため、こちらの注文書はキャンセル状態になります。`,
      successBtn: proxy.$fanyi("确定"),
      cancelBtn: proxy.$fanyi("取消"),
      successMethods: () => {
        proxy.$api
          .updateOrderStatus({
            order_sn: proxy.$route.query.order_sn,
          })
          .then((res) => {
            if (res.code != 0)
              return proxy.$message.error(proxy.$fanyi(res.msg));
            proxy.$message.success(proxy.$fanyi(res.msg));
            setTimeout(() => {
              proxy.$fun.routerToPage(`/user/order?type=payment`);
            }, 1000);
          });
      },
    });
  }
  // proxy.$fun.routerToPage(
  //   `/payment?order_sn=${proxy.$route.query.order_sn}&discounts_id=${
  //     usedCouponInfo.value.id || ""
  //   }`
  // );
};
defineExpose({
  userOperationMode,
  totalPrice,
  orderNumData,
  lists,
  shippingMethodList,
  checked,
  client_remark,
  logistics_id,
  orderStatus,
  orderStatusNum,
  hasUsedCoupon,
  usedCouponInfo,
  allDetails,
  orderModification,
  logistics,
  couponList,
  exchange_rate,
  isPay,
  otherPriceData,

  isProposedAgain,
  loading,
  proposeAgain,
  unmodify,
  reintroduce,
  delCart,
  allChoice,
  openOrderDetailModal,
  shopAll,
  oddChoice,
  allChoice,
  appendCommodity,
  openDetailSheet,
  editSingleProductAttribute,
  createOrder,
  openAdditionalService,
  openUseCouponModal,
  commodityTotal,
  proceedToPayment,
  getProductSpecificationData,
});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.pageF6Color {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.placeHoldFont {
  color: #c6c6c6;
}
.redFont {
  color: #b4272b !important;
}
input[type="checkbox"] {
  width: 30px;
  flex: 0 0 30px;
  height: 30px;
  border: 1px solid #dfdfdf;
  border-radius: 6px;
}

.headerButtonBox {
  button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 35px;
    background-color: transparent;
    font-size: 24px !important;
    font-weight: 400 !important;
    color: #000000;
  }

  button.redFont {
    font-weight: 600 !important;
  }
}
.technicalSupport {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #999999;
  margin-top: -35px;
}
</style>
