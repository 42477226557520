<!-- 订单详情附加服务选择 -->
<template>
  <van-action-sheet
    v-model:show="show"
    :title="
      ['临时保存待提出'].includes($parent.orderStatus)
        ? $fanyi('附加服务选择')
        : $fanyi('附加服务查看')
    "
    class="productAttributeSelectionSheet"
  >
    <div class="modelBox">
      <div class="additionalServiceList">
        <div
          class="additionalServiceOpt"
          v-for="additionalServiceItem in productData.option"
          :key="additionalServiceItem.id"
        >
          <input
            v-if="!isReadOnly"
            type="checkbox"
            v-model="additionalServiceItem.checked"
            @change="
              additionalServiceItem.num = productData.num;
              !additionalServiceItem.checked
                ? (additionalServiceItem.num = 0)
                : '';

              calculateAdditionalServicePrice();
            "
          />
          <ul class="additionalServiceInfo">
            <li>
              <span
                class="aname"
                @click="
                  additionalServiceItem.price_type == 0
                    ? (additionalServiceItem.num = productData.num)
                    : '';
                  additionalServiceItem.checked =
                    !additionalServiceItem.checked;
                  calculateAdditionalServicePrice();
                "
              >
                {{ additionalServiceItem.sort }}）
                {{ additionalServiceItem.name_translate }}
              </span>
              <span class="anum">
                {{ $fanyi("数量") }}：
                <input
                  type="number"
                  step="1"
                  required
                  :readonly="!additionalServiceItem.checked"
                  :class="{ readOnly: isReadOnly }"
                  v-model="additionalServiceItem.num"
                  @input="calculateAdditionalServicePrice"
                  @change="
                    !additionalServiceItem.num
                      ? (additionalServiceItem.num = 0)
                      : ''
                  "
                />
              </span>
            </li>
            <li>
              <span class="aprice">
                <span class="priNum"> {{ additionalServiceItem.price }} </span>
                {{ additionalServiceItem.unit }}/点
              </span>
              <span class="atotal">
                {{ $fanyi("小计") }}：<span class="totalNum">
                  {{ $fun.ceil(additionalServiceItem.total) }}元({{
                    $fun.roundNumber(
                      additionalServiceItem.total * $parent.exchange_rate
                    )
                  }}円)
                </span>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <!-- 完成按钮 -->
      <div class="boxCon bottomBox" v-if="!isReadOnly">
        <span class="totalPrice">
          <span>{{ $fanyi("总计") }}：</span>
          <span class="priceFOnt"
            >{{ $fun.ceil(totalAdditionalServicePrice) }}元({{
              $fun.roundNumber(
                totalAdditionalServicePrice * $parent.exchange_rate
              )
            }}円)</span
          >
        </span>
        <button class="addToCartButton" @click="saveModifiedData">
          {{ $fanyi("确认") }}
        </button>
      </div>
    </div>
  </van-action-sheet>
</template>
<script setup>
import { getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
const productData = ref({}); //单个商品的数据
let parentComponentData = ref(null); //父组件里的数据(修改数据用)
let confirmEvent = null;
const show = ref(false);
const totalAdditionalServicePrice = ref(0); //选择的附加服务总价格
const isReadOnly = ref(); //弹窗是否只读
//------------------------ pageLoad -------------------------------------
//------------------------ methods -------------------------------------
const open = (data, methods, showType) => {
  parentComponentData.value = data;
  productData.value = JSON.parse(JSON.stringify(data));
  confirmEvent = methods;
  show.value = true;
  isReadOnly.value = showType;
  calculateAdditionalServicePrice();
};
// 计算附加服务价格
const calculateAdditionalServicePrice = () => {
  let total = 0;
  productData.value.option.forEach((optionelement) => {
    // optionelement-单个商品的附加服务列表
    if (!optionelement.price_type) {
      optionelement.total = optionelement.price * (optionelement.num || 0);
    } else {
      optionelement.total =
        productData.value.price *
        optionelement.num *
        (optionelement.price / 100);
      // optionelement.num = 1;
    }
    if (optionelement.checked) {
      total += optionelement.total;
    }
    totalAdditionalServicePrice.value = total;
  });
};

// 将修改的选择保存
const saveModifiedData = () => {
  parentComponentData.value.option = productData.value.option;
  show.value = false;
  //   假如有传入事件就执行事件
  if (confirmEvent) {
    confirmEvent();
  }
};
defineExpose({ open });
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.priceFOnt {
  font-weight: 600;
  color: #b4272b;
}

input[type="checkbox"] {
  border: #dfdfdf solid 1px;
  flex: 0 0 22px;
  height: 22px;
}

.modelBox {
  padding-bottom: 110px;

  .forHead {
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .boxCon {
    margin: 0 30px;

    .sheetTitle {
      line-height: 1;
      padding: 45px 0 40px;
      text-align: center;
      font-size: 30px;
    }

    .goodsInfoBox {
      display: flex;
      padding-bottom: 30px;
      margin-bottom: 2px;

      .goodsTitle {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 28px;
      }

      .goodsPrice {
        font-size: 30px;
        font-weight: 600;
        color: #b4272b;
      }

      .goodsImg {
        flex: 0 0 120px;
        height: 120px;
        margin-right: 30px;
        border-radius: 6px;
      }
    }
  }

  .additionalServiceList {
    min-height: 400px;
    padding: 0 30px;
    padding-top: 23px;

    .additionalServiceOpt {
      padding: 24px 0;
      display: flex;
      align-items: flex-start;

      input[type="checkbox"] {
        width: 22px;
        height: 22px;
        margin-right: 20px;
        margin-top: 10px;
      }

      .additionalServiceInfo {
        flex: 1;

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:first-child {
            margin-bottom: 19px;
          }

          span {
            font-size: 24px;
          }

          .aname {
            font-weight: 600;
          }

          .anum {
            min-height: 42px;

            input {
              width: 120px;
              height: 42px;
              background: #ffffff;
              border: 1px solid #dfdfdf;
              border-radius: 6px;
              text-align: center;
            }

            .readOnly {
              background: #f2f2f2;
              pointer-events: none;
            }
          }

          .aprice {
            .priNum {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              width: 80px;
              height: 42px;
              background: #f2f2f2;
              border-radius: 6px;
              margin-right: 3px;
            }
          }

          .atotal {
            .totalNum {
              font-weight: 600;
              color: #b4272b;
            }
          }
        }
      }
    }
  }

  .bottomBox {
    background-color: white;
    position: sticky;
    padding: 15px 0;
    overflow: hidden;
    bottom: 119px;
    // width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .totalPrice {
      span {
        font-size: 24px;
      }
    }

    button {
      width: 150px;
      height: 70px;
      background: #b4272b;
      border-radius: 6px;
      font-size: 28px;

      font-weight: 400;
      color: #ffffff;
      margin-left: auto;
    }
  }
}
</style>
<style lang="scss">
.productAttributeSelectionSheet {
  .van-action-sheet__header {
    text-align: center !important;
    font-size: 30px;
  }
}
</style>
