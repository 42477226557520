<template>
  <van-action-sheet
    v-model:show="show"
    :title="$fanyi('追加商品')"
    class="productAttributeSelectionSheet"
  >
    <div class="modelBox">
      <div class="selectSource">
        <span
          :class="{ active: productSource == 'cart' }"
          @click="swipeTo(0)"
          >{{ $fanyi("购物车") }}</span
        >
        <span
          :class="{ active: productSource == 'productStore' }"
          @click="swipeTo(1)"
          >{{ $fanyi("商品库") }}</span
        >
      </div>
      <van-swipe
        @change="changeData"
        class="my-swipe"
        ref="swipeRef"
        indicator-color="white"
      >
        <van-swipe-item>
          <div class="cartCon" v-if="productSource == 'cart'">
            <div class="shopsBox" v-for="(item, index) in lists" :key="index">
              <h2>
                <input
                  type="checkbox"
                  name=""
                  id=""
                  v-model="item.checked"
                  @change="shopAll(index)"
                />

                <img :src="require('@/assets/icon/dianpu.png')" alt="" />
                <span>{{ item.shop_name }}</span>
              </h2>
              <div
                class="goodsBox"
                v-for="(listItem, listIndex) in item.goods"
                :key="listIndex"
              >
                <input
                  type="checkbox"
                  v-model="listItem.checked"
                  @change="oddChoice(index)"
                />
                <div class="productPurchaseInfo">
                  <div class="productInfo">
                    <van-image
                      lazy-load
                      class="productImage"
                      :src="listItem.pic"
                      @click="$lookImg(listItem.pic)"
                    >
                      <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                      </template>
                    </van-image>
                    <div>
                      <h3>
                        {{ listItem.goods_title }}
                      </h3>
                      <div class="productAttribute">
                        <p>
                          <span
                            v-for="(detailItem, detailIndex) in listItem.detail"
                            :key="detailIndex"
                          >
                            {{ detailItem.value }};
                          </span>
                        </p>
                        <img
                          class="arrow-down"
                          :src="require('@/assets/icon/arrow-downskdjfh.png')"
                          alt=""
                        />
                      </div>
                      <div class="quantityAndAmount">
                        <div class="amount">
                          <b>
                            {{
                              $fun.RMBNumSegmentation(
                                listItem.univalence
                                  ? listItem.univalence
                                  : Number(listItem.price)
                              )
                            }}元</b
                          >(
                          {{
                            $fun.JPYNumSegmentation(
                              $fun.roundNumber(
                                (listItem.univalence
                                  ? listItem.univalence
                                  : Number(listItem.price)) *
                                  $store.state.exchangeRate
                              )
                            )
                          }}円)
                        </div>
                        <van-stepper
                          integer
                          style="pointer-events: none"
                          v-model.number="listItem.num"
                          @change="editSingleProductAttribute(listItem)"
                        />
                      </div>
                    </div>
                  </div>
                  <textarea
                    class="customerNote"
                    :placeholder="$fanyi('请输入备注') + '...'"
                    v-model="listItem.client_remark"
                    v-autoHeightForLineBreak
                    @change="editSingleProductAttribute(listItem)"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <div class="cartCon" v-if="productSource == 'productStore'">
            <div class="shopsBox" v-for="(item, index) in lists" :key="index">
              <h2>
                <input
                  type="checkbox"
                  name=""
                  id=""
                  v-model="item.checked"
                  @change="shopAll(index)"
                />

                <img :src="require('@/assets/icon/dianpu.png')" alt="" />
                <span>{{ item.shop_name }}</span>
              </h2>
              <div
                class="goodsBox"
                v-for="(listItem, listIndex) in item.goods"
                :key="listIndex"
              >
                <input
                  type="checkbox"
                  v-model="listItem.checked"
                  @change="oddChoice(index)"
                />
                <div class="productPurchaseInfo">
                  <div class="productInfo">
                    <van-image
                      lazy-load
                      class="productImage"
                      :src="listItem.pic"
                      @click="$lookImg(listItem.pic)"
                    >
                      <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                      </template>
                    </van-image>
                    <div>
                      <h3>
                        {{ listItem.goods_title }}
                      </h3>
                      <div class="productAttribute">
                        <p>
                          <span
                            v-for="(detailItem, detailIndex) in listItem.detail"
                            :key="detailIndex"
                          >
                            {{ detailItem.value }};
                          </span>
                        </p>
                        <img
                          class="arrow-down"
                          :src="require('@/assets/icon/arrow-downskdjfh.png')"
                          alt=""
                        />
                      </div>
                      <div class="quantityAndAmount">
                        <div class="amount">
                          <b>
                            {{
                              $fun.RMBNumSegmentation(
                                listItem.univalence
                                  ? listItem.univalence
                                  : Number(listItem.price)
                              )
                            }}元</b
                          >(
                          {{
                            $fun.JPYNumSegmentation(
                              $fun.roundNumber(
                                (listItem.univalence
                                  ? listItem.univalence
                                  : Number(listItem.price)) *
                                  $store.state.exchangeRate
                              )
                            )
                          }}円)
                        </div>
                        <van-stepper
                          integer
                          style="pointer-events: none"
                          v-model.number="listItem.num"
                          @change="editSingleProductAttribute(listItem)"
                        />
                      </div>
                    </div>
                  </div>
                  <textarea
                    class="customerNote"
                    :placeholder="$fanyi('请输入备注') + '...'"
                    v-model="listItem.client_remark"
                    v-autoHeightForLineBreak
                    @change="editSingleProductAttribute(listItem)"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
      <productAttributeSelectionVue ref="productAttributeSelectionVueRef" />
      <!-- 完成按钮 -->
      <div class="bottomBox">
        <input
          type="checkbox"
          name=""
          id=""
          v-model="checked"
          @change="allChoice"
        />
        <span
          class="quanXuan"
          @click="
            checked = !checked;
            allChoice();
          "
          >{{ $fanyi("全选") }}</span
        >
        <span>
          {{ $fanyi("数量") }}
          <span class="redFont">{{ goodsTotal }}</span>
        </span>
        <button class="addAdditionalButton" @click="addAdditional">
          {{ $fanyi("追加") }}
        </button>
      </div>
    </div>
  </van-action-sheet>
</template>
<script>
export default {
  methods: {
    openddd(v) {
      this.$refs.swipeRef.swipeTo(v);
    },
  },
};
</script>
<script setup>
import productAttributeSelectionVue from "./productAttributeSelection.vue";
import { getCurrentInstance, ref, onMounted } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data ----------------------------------------
const show = ref(false);
const checked = ref(false); //全选
const goodsTotal = ref(0); //选中的商品种类
const isTotal = ref(0); // 选中的商品所有数量
const shopTotal = ref(0); // 选中的店铺
const totalPrice = ref(0); // 选中的商品总价
const lists = ref(null); // 数据
// const lists = ref(null); //商品库数据
const idArr = ref([]); // 选中的商品ID
const overlook_cart_ids = ref([]); //订单详情里面已经拥有的商品的id  例:12,33,2
const productSource = ref("cart"); //商品来源 cart-购物车 productStore-商品库
//------------------------ pageLoad -------------------------------------
onMounted(() => {});
//------------------------ methods -------------------------------------
// 选择商品来源
const selectProductSource = (type) => {
  productSource.value = type;
  getCartList(overlook_cart_ids.value);
  checked.value = false;
};
const swipeTo = (v) => {
  proxy.openddd(v);
};
const changeData = (v) => {
  lists.value = [];
  switch (v) {
    case 0:
      selectProductSource("cart");
      break;

    case 1:
      selectProductSource("productStore");
      break;
  }
};
// 判断是否超过最大商品数量 提取出选中的数据 调用父组件函数接收数据 关闭追加商品弹窗
const addAdditional = () => {
  let appendData = JSON.parse(JSON.stringify(lists.value));
  for (let i = 0; i < appendData.length; i++) {
    // shopItem-单个店铺所有数据
    let shopItem = appendData[i];
    for (let j = 0; j < appendData[i].goods.length; j++) {
      // goodsItem - 单个商品所有数据;
      let goodsItem = appendData[i].goods[j];
      if (!goodsItem.checked) {
        shopItem.goods.splice(j, 1);
        j--;
      }
    }
    if (shopItem.goods.length == 0) {
      appendData.splice(i, 1);
      i--;
    }
  }
  proxy.$parent.appendCommodity(appendData, productSource.value);
  // 关闭追加商品弹窗
  show.value = false;
};
// 传参:{
// overlook_cart_ids-订单详情里面已经拥有的商品的id  例:12,33,2
// }
const open = (data) => {
  show.value = true;
  checked.value = false;
  getCartList(data.overlook_cart_ids);
  overlook_cart_ids.value = data.overlook_cart_ids; //将已有商品id保存起来以供切换商品来源时使用
};

// 匹配单个商品的价格区间
const priceRangeMatching = (listItem) => {
  // listItem-单个商品所有数据
  // 设置默认的价格区间
  listItem.priceRange = listItem.goodsInventory.find((InventoryItem) => {
    // InventoryItem-单个商品里的单条sku对象
    // 拼接出匹配sku的字符串
    let searchstr = listItem.detail.map((obj) => obj.value).join("㊖㊎");
    return InventoryItem.keyC == searchstr || InventoryItem.keyT == searchstr;
  }).valueC;
};
// 递归查找数组里符合条件的对象(只会寻找key是goods的子数组)(传参:数组,要匹配的key名字,要匹配的value)
const findAllObjectsWithPropertyName = (array, propertyName, propertyValue) => {
  return array.flatMap((obj) => {
    if (obj[propertyName] === propertyValue) {
      return obj;
    } else if (obj.goods) {
      return findAllObjectsWithPropertyName(
        obj.goods,
        propertyName,
        propertyValue
      );
    } else {
      return [];
    }
  });
};

// 获取数据(购物车来源商品和商品库来源商品)
const getCartList = (overlook_cart_ids) => {
  goodsTotal.value = 0;
  // 商品库追加商品获取数据
  if (productSource.value == "productStore") {
    proxy.$api
      .orderAddInventoryGoods({
        overlook_inventory_ids: overlook_cart_ids.join(","),
      })
      .then((res) => {
        if (res.code != 0) {
          lists.value = [];
          return false;
        }
        lists.value = res.data;
        lists.value.forEach((item) => {
          item.goods.forEach((goodsItem) => {
            goodsItem.client_remark = goodsItem.note;
            goodsItem.num = 1;
          });
        });
      });
  } else if (productSource.value == "cart") {
    proxy.$api
      .orderAddCartGoods({ overlook_cart_ids: overlook_cart_ids.join(",") })
      .then((res) => {
        if (res.code != 0) {
          lists.value = [];
          return false;
        }
        lists.value = res.data;
        getOtherData();
      });
  }
};
// 从接口获取数据时获取所有商品的价格区间和所有规格列表数据
const getOtherData = () => {
  //   获取所有商品的id和type

  lists.value.forEach((item) => {
    item.goods.forEach((goodsItem) => {
      if (typeof goodsItem.detail === "string") {
        goodsItem.detail = JSON.parse(goodsItem.detail);
      }
      goodsItem.leftIcon = false;
      goodsItem.checkLeftIcon = false;
      goodsItem.showGoodsDetails = false;
    });
  });

  //调用接口获取各个商品的规格属性及不同SKU单价并拼接到lists上
  // 设置默认chiose
  // 设置默认价格区间

  tidyData();
};
// 整理数据
const tidyData = () => {
  lists.value.forEach((item) => {
    item.type = item.goods[0].type; // 店铺类别
    item.goods.forEach((item1) => {
      // 商品总数
      item1.checked = false; // 商品选择
      item1.univalence = 0; // 显示单价
      item1.total = 0;
      // item1.price_range = JSON.parse(item1.price); // 字符串转换组数
    });
  });
  subtotal();
};
// 编辑单个商品属性
const editSingleProductAttribute = (listItem) => {
  // 计算金钱
  subtotal();
  commodityTotal();
  //   将修改提交给后台
  let datas = JSON.parse(JSON.stringify(listItem));
  datas.detail = JSON.stringify(datas.detail);
  proxy.$api.goodsCartEdit(datas).then((res) => {
    ////console.log('事件名',res)
    if (res.code != 0) return;
    //接下来的操作
  });
};
// 判断每一番商品使用哪个价格 并计算单个商品总价
const subtotal = () => {
  lists.value.forEach((item) => {
    item.goods.forEach((listItem) => {
      listItem.univalence = 0;
      // console.log(listItem.priceRange);
      if (listItem.priceRange) {
        for (let i = listItem.priceRange.length - 1; i >= 0; i--) {
          // priceItem-单个商品里的单条价格数据
          const priceItem = listItem.priceRange[i];
          if (priceItem.startQuantity <= listItem.num) {
            listItem.univalence = priceItem.price;
            break;
          }
        }
      }
      listItem.total = proxy.$fun.ceil(
        listItem.num *
          (!!listItem.univalence ? listItem.univalence : listItem.price)
      );
    });
  });
};

// 单选
const oddChoice = (i) => {
  let flag = lists.value[i].goods.every((item) => item.checked == true);
  if (flag) {
    lists.value[i].checked = true;
  } else {
    lists.value[i].checked = false;
  }
  isCheckedAll();
  commodityTotal();

  //   flag ? (lists.value[i].checked = true) : (lists.value[i].checked = false);
};
// 店铺全选
const shopAll = (i) => {
  if (lists.value[i].checked) {
    lists.value[i].goods.forEach((item) => {
      item.checked = true;
    });
  } else {
    lists.value[i].goods.forEach((item) => {
      item.checked = false;
    });
  }

  isCheckedAll();
  commodityTotal();
};
// 全选
const allChoice = () => {
  if (checked.value) {
    lists.value.forEach((item, index) => {
      item.checked = true;
      shopAll(index);
    });
  } else {
    lists.value.forEach((item, index) => {
      item.checked = false;
      shopAll(index);
    });
  }
  commodityTotal();
};
// 是否全选中
const isCheckedAll = () => {
  var flag = lists.value.every((item, index) => item.checked == true);
  if (flag == true) {
    checked.value = true;
  } else {
    checked.value = false;
  }
};
// 统计数据 选中商品数量总数、总价
const commodityTotal = () => {
  totalPrice.value = 0; //选中的商品总价
  isTotal.value = 0; //选中的商品所有数量
  shopTotal.value = 0;
  goodsTotal.value = 0;
  idArr.value = [];
  lists.value.forEach((item, i) => {
    if (item.goods.some((items) => items.checked)) {
      shopTotal.value += 1;
    }
    item.goods.forEach((listItem, j) => {
      if (listItem.checked) {
        totalPrice.value += listItem.total - 0;
        isTotal.value += listItem.num;
        goodsTotal.value += 1;
        idArr.value.push(listItem.id);
      }
    });
  });
};

defineExpose({ open });
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.redFont {
  color: #b4272b !important;
}
input[type="checkbox"] {
  width: 30px;
  flex: 0 0 30px;
  height: 30px;
  border: 1px solid #dfdfdf;
  border-radius: 6px;
}
.modelBox {
  padding-bottom: 240px;
  background-color: #f6f6f6;
  .selectSource {
    height: 98px;
    position: sticky;
    top: -1px;
    background-color: white;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    span {
      padding: 0 20px;
      font-size: 28px;
    }
    span.active {
      font-weight: 600;
      color: #b4272b;
      font-size: 32px;
    }
  }
  .bottomBox {
    background-color: white;
    position: fixed;
    padding: 15px 30px;
    overflow: hidden;
    bottom: 119px;
    display: flex;
    width: 100%;
    align-items: center;
    span {
      font-size: 24px;
    }
    .quanXuan {
      margin: 6px;
    }
    .redFont {
      font-weight: 600;
    }
    .addAdditionalButton {
      margin-left: auto;
      width: 150px;
      height: 70px;
      background: #b4272b;
      font-size: 24px;
      border-radius: 6px;
      padding: 22px;
      color: white;
    }
  }
  .cartCon {
    padding: 20px 30px;
    min-height: 600px;
    .shopsBox {
      padding: 30px;
      background: #ffffff;
      border-radius: 6px;
      margin-bottom: 15px;
      h2 {
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        input[type="checkbox"] {
          margin-right: 13px;
        }
        img {
          width: 32px;
          height: 30px;
          margin-right: 7px;
        }
        span {
          font-size: 28px;
          line-height: 1;
        }
      }
      .goodsBox {
        display: flex;
        margin-bottom: 24px;
        input[type="checkbox"] {
          margin-top: 6px;
          margin-right: 13px;
        }
        .productPurchaseInfo {
          .productInfo {
            display: flex;
            margin-bottom: 18px;
            .productImage {
              flex: 0 0 180px;
              height: 180px;
              margin-right: 24px;
              border-radius: 6px;
            }
            h3 {
              margin-top: -4px;
              font-size: 28px;
              line-height: 32px;
              margin-bottom: 15px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .productAttribute {
              width: 350px;
              height: 42px;
              background: #ffffff;
              border: 1px solid #dfdfdf;
              border-radius: 6px;
              display: flex;
              align-items: center;
              padding: 0 12px;
              margin-bottom: 18px;
              p {
                line-height: 42px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 24px;

                span {
                  font-size: 24px;
                }
              }
              .arrow-down {
                width: 16px;
                height: 9.5px;
                line-height: 1;
                font-weight: 700;
                color: #333333;
                margin-left: auto;
              }
            }
            .quantityAndAmount {
              width: 370px;
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
              .amount {
                font-size: 24px;
                color: #b4272b;
                b {
                  font-size: 28px;
                  font-weight: 600;
                }
              }
              .van-stepper,
              :deep(.van-stepper) {
                width: 146px;
                button {
                  flex: 0 0 40px;
                  height: 40px;
                }
                input {
                  flex: 0 0 60px;
                  width: 60px;
                  height: 40px;
                  margin: 0 3px;
                }
              }
            }
          }
          .customerNote {
            width: 100%;
            height: 42px;
            background: #ffffff;
            border: 1px solid #dfdfdf;
            border-radius: 6px;
            font-size: 24px;
            line-height: 40px;
            padding: 0px 12px;
            pointer-events: none;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.productAttributeSelectionSheet {
  height: 70vh;
  .van-action-sheet__header {
    text-align: center !important;
    font-size: 30px;
  }
}
</style>
