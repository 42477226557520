<template>
  <van-action-sheet
    v-model:show="show"
    :title="headStr"
    class="productAttributeSelectionSheet"
  >
    <div class="modelBox">
      <div class="forHead">
        <!-- 商品标题 -->
        <div class="boxCon">
          <div class="goodsInfoBox">
            <van-image
              lazy-load
              class="goodsImg"
              :src="productData.pic"
              @click="$lookImg(productData.pic)"
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
            <div>
              <div class="goodsTitle">
                {{ productData.goods_title }}
              </div>
              <div class="goodsPrice" v-if="!isLook">
                {{ $fun.RMBNumSegmentation(Number(showPrice)) }}元({{
                  $fun.JPYNumSegmentation(
                    $fun.roundNumber(showPrice * $parent.exchange_rate)
                  )
                }}円)
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 商品属性(不可编辑) -->
      <div class="boxCon" v-if="isLook">
        <div class="readOnlyProductDetailsBox">
          <div
            class="readOnlyProductDetails"
            v-for="(detailsItem, detailsIndex) in productData.detail"
            :key="'detailsItem' + detailsIndex"
          >
            <label class="readOnlydetailName"> {{ detailsItem.key }} </label>
            <div class="readOnlydetailCon">
              {{ detailsItem.value }}
            </div>
          </div>
        </div>
      </div>
      <!-- 商品属性(可编辑) -->
      <div class="boxCon" v-else>
        <div class="goodsDetailBox">
          <div
            class="goodsDetail"
            v-for="(detailsItem, detailsIndex) in productData.specification"
            :key="'detailsItem' + detailsIndex"
          >
            <label class="detailName"> {{ detailsItem.keyT }} </label>
            <div class="detailOptBox">
              <div class="detailOptCon">
                <div
                  class="detailCon"
                  v-for="(valueItem, valueIndex) in detailsItem.valueC"
                  :key="'valueItem' + valueIndex"
                  :class="{ activeDetail: detailsItem.chiose == valueIndex }"
                  @click="
                    // 将主图替换成商品选项里的图片
                    replacePicture(valueItem);

                    setProductAttributes(detailsItem, detailsIndex, valueIndex);
                  "
                >
                  {{ valueItem.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 完成按钮 -->
      <div class="boxCon bottomBox" v-if="!isLook">
        <div>
          <button class="addToCartButton" @click="saveModifiedData">
            <img id="addCart" alt="" style="display: none" />
            {{ $fanyi("完成") }}
          </button>
        </div>
      </div>
    </div>
  </van-action-sheet>
</template>
<script setup>
import { getCurrentInstance, ref, computed } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
const productData = ref({}); //单个商品的数据
let parentComponentData = ref(null); //父组件里的数据(修改数据用)
let confirmEvent = null;
const show = ref(false);
const isLook = ref(false);
const headStr = ref(proxy.$fanyi("规格选择"));
//商品显示单价
const showPrice = computed(() => {
  if (isLook.value) {
    // 规格查看的时候直接展示price字段
    return productData.value.price;
  } else {
    return productData.value.price || 0;
  }
});
//------------------------ pageLoad -------------------------------------
//------------------------ methods --------------------------------------
const open = (data, methods, type) => {
  // data-单个商品所有数据 methods-点击确认按钮的执行事件,type-查看或者选择
  parentComponentData.value = data;
  productData.value = JSON.parse(JSON.stringify(data));

  confirmEvent = methods;
  show.value = true;
  isLook.value = type;
  if (type) {
    headStr.value = proxy.$fanyi("商品详情");
  } else {
    headStr.value = proxy.$fanyi("规格选择");
  }
}; // 将主图替换成商品选项里的图片
const replacePicture = (valueItem) => {
  if (valueItem.picUrl) {
    productData.value.pic = valueItem.picUrl;
  }
};
// 设置商品属性
const setProductAttributes = (detailsItem, detailsIndex, valueIndex) => {
  detailsItem.chiose = valueIndex;
  productData.value.detail[detailsIndex].value =
    detailsItem.valueC[detailsItem.chiose].name;
  // 根据商品选择的规格和商品数量匹配商品价格
  priceRangeMatching(productData.value);
};
// 根据用户选择商品规格匹配单个商品的价格区间和修改商品的skuid和specid
const priceRangeMatching = (listItem) => {
  // listItem-单个商品所有数据
  // 设置默认的价格区间
  // 拼接出匹配sku的字符串
  let searchstr = listItem.detail.map((obj) => obj.value).join("㊖㊎");
  let pricerange = listItem.goodsInventory.find((InventoryItem) => {
    // InventoryItem-单个商品里的单条sku对象
    return InventoryItem.keyC == searchstr || InventoryItem.keyT == searchstr;
  });
  if (pricerange) {
    listItem.priceRange = pricerange.valueC;
  }
  // 从pricerange里根据数量匹配出使用的数据 如果购买数量大于等于startQuantity就使用那条数据,
  // 如果购买数量小于最小的那一条数据的startQuantity就使用startQuantity最小的那一条数据,也就是第一条数据
  let useDataIndex = proxy.$fun.findIndexLast(listItem.priceRange, (item) => {
    return listItem.num >= item.startQuantity;
  });
  if (listItem.priceRange && listItem.priceRange.length) {
    listItem.price = listItem.priceRange[useDataIndex].price;
  }
  // 修改商品的skuid和specid
  if (listItem.goodsInventory) {
    listItem.goodsInventory.forEach((goodsInventoryItem) => {
      if (
        searchstr === goodsInventoryItem.keyC ||
        searchstr === goodsInventoryItem.keyT
      ) {
        listItem.sku_id = goodsInventoryItem.valueC[0].skuId;
        listItem.spec_id = goodsInventoryItem.valueC[0].specId;
      }
    });
  }
};

// 将修改的选择保存
const saveModifiedData = () => {
  // 将传入的商品数据修改为用户选择的商品数据;
  for (let i in productData.value) {
    parentComponentData.value[i] = productData.value[i];
  }
  // console.log(parentComponentData.value);
  show.value = false;
  //   假如又传入事件就执行事件
  if (confirmEvent) {
    confirmEvent();
  }
};
defineExpose({ open });
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.modelBox {
  padding-bottom: 240px;

  .forHead {
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .boxCon {
    margin: 0 30px;

    .sheetTitle {
      line-height: 1;
      padding: 45px 0 40px;
      text-align: center;
      font-size: 30px;
    }

    .goodsInfoBox {
      display: flex;
      padding-bottom: 30px;
      margin-bottom: 2px;

      .goodsTitle {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 28px;
      }

      .goodsPrice {
        font-size: 30px;
        font-weight: 600;
        color: #b4272b;
      }

      .goodsImg {
        flex: 0 0 120px;
        height: 120px;
        margin-right: 30px;
        border-radius: 6px;
      }
    }
    .readOnlyProductDetailsBox {
      border-top: 1px solid #dfdfdf;
      min-height: 400px;
      .readOnlyProductDetails {
        display: flex;
        align-items: center;
        min-height: 70px;
        padding: 20px 0;
        .readOnlydetailName {
          flex: 0 0 180px;
          font-size: 24px;
        }
        .readOnlydetailCon {
          font-size: 24px;
        }
      }
    }

    .goodsDetailBox {
      overflow: hidden;

      .goodsDetail {
        font-size: 28px;
        border-top: #dfdfdf solid 1px;
        padding: 30px 0;

        .detailName {
          display: flex;
          font-size: 28px;
          align-items: center;
          min-width: 75px;
          font-weight: 400;
          color: #7b7b7b;
        }

        .detailOptBox {
          .detailOptCon {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .detailCon {
              margin-top: 30px;
              margin-right: 20px;
              padding: 0 25px;
              min-height: 53px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #f2f2f2;
              border-radius: 6px;
              white-space: nowrap;
              margin-right: 20px;

              .goodsImg {
                flex: 0 0 40px;
                height: 40px;
                margin-right: 15px;
              }
            }

            .detailCon.activeDetail {
              background: #b4272b;
              color: white;
            }
          }
        }
      }
    }

    .addToCartButton {
      width: 690px;
      height: 70px;
      background: #b4272b;
      font-size: 28px;
      border-radius: 6px;
      padding: 22px;
      color: white;
    }
  }

  .bottomBox {
    background-color: white;
    position: fixed;
    padding: 15px 0;
    overflow: hidden;
    bottom: 119px;
  }
}
</style>
<style lang="scss">
.productAttributeSelectionSheet {
  .van-action-sheet__header {
    text-align: center !important;
    font-size: 30px;
  }
}
</style>
