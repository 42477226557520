<!-- 临时保存状态订单列表 -->
<template>
  <div>
    <div class="cartCon">
      <div class="shopsBox" v-for="(item, index) in lists" :key="index">
        <div
          class="onlygoodsBox"
          v-for="(listItem, listIndex) in item.goods"
          :key="listIndex"
        >
          <h2>
            <!-- <input
              v-if="userOperationMode == 'delete'"
              type="checkbox"
              name=""
              id=""
              v-model="item.checked"
              @change="shopAll(index)"
            /> -->

            <img :src="require('@/assets/icon/dingdanfanhao.png')" alt="" />
            <span>番号：#{{ listItem.sort }}</span>
          </h2>
          <div class="goodsBox">
            <input
              v-if="userOperationMode == 'delete'"
              type="checkbox"
              v-model="listItem.checked"
              @change="oddChoice(index)"
            />
            <div class="productPurchaseInfo">
              <div class="productInfo">
                <!-- 2023/7/20 业务员反应需要点击图片跳转到对应商品链接 -->
                <!-- 截图: https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202307/64b8fbc8ad97c.png -->

                <van-image
                  lazy-load
                  class="productImage"
                  :src="listItem.pic"
                  @click="
                    $fun.routerToPage(
                      `ProductDetails?goods_id=${listItem.goods_id}&fromPlatform=${listItem.from_platform}`
                    )
                  "
                >
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
                <div class="goodsSmalInfo">
                  <h3
                    @click="
                      $fun.toCommodityDetails(
                        listItem.goods_id,
                        listItem.from_platform
                      )
                    "
                  >
                    <span
                      class="icon1688"
                      v-if="listItem.from_platform == '1688'"
                    >
                      <img
                        :src="require('@/assets/icon/icon1688.png')"
                        alt=""
                      />
                    </span>
                    {{ listItem.goods_title }}
                  </h3>
                  <div
                    class="productAttribute"
                    @click="
                      $parent.getProductSpecificationData({
                        item: listItem,
                        omethods: () => {
                          openDetailSheet(listItem, false);
                        },
                      })
                    "
                  >
                    <p>
                      <span
                        v-for="(detailItem, detailIndex) in listItem.detail"
                        :key="detailIndex"
                      >
                        {{ detailItem.value }};
                      </span>
                    </p>

                    <img
                      class="arrow-down"
                      :src="require('@/assets/icon/arrow-downskdjfh.png')"
                      alt=""
                    />
                  </div>
                  <div class="quantityAndAmount">
                    <div class="amount">
                      <b>
                        {{
                          $fun.RMBNumSegmentation(
                            listItem.univalence
                              ? listItem.univalence
                              : Number(listItem.price)
                          )
                        }}元</b
                      >(
                      {{
                        $fun.JPYNumSegmentation(
                          $fun.roundNumber(
                            (listItem.univalence
                              ? listItem.univalence
                              : Number(listItem.price)) * $parent.exchange_rate
                          )
                        )
                      }}円)
                    </div>
                    <van-stepper
                      integer
                      v-model.number="listItem.num"
                      @change="editSingleProductAttribute(listItem)"
                    />
                  </div>
                </div>
              </div>
              <div
                class="additionalService"
                @click="openAdditionalService(listItem)"
              >
                <p>
                  <span
                    v-for="optionItem in listItem.option"
                    :key="optionItem.id"
                    v-show="optionItem.checked && optionItem.num > 0"
                  >
                    <span class="tag">{{
                      $fun.matchTag(optionItem.sort)
                    }}</span>
                    *{{ optionItem.num }}；
                  </span>
                  <span
                    class="placeHoldFont"
                    v-if="
                      listItem.option &&
                      !listItem.option.find((item) => {
                        return item.checked == true && item.num > 0;
                      })
                    "
                    >{{ $fanyi("请选择附加服务") }}</span
                  >
                </p>
              </div>

              <textarea
                class="customerNote"
                :placeholder="$fanyi('请输入备注...')"
                v-autoHeightForLineBreak
                v-model="listItem.client_remark"
                @change="editSingleProductAttribute(listItem)"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, computed } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
const lists = computed(() => {
  return proxy.$parent.lists;
});
const userOperationMode = computed(() => {
  return proxy.$parent.userOperationMode;
});
const shopAll = computed(() => {
  return proxy.$parent.shopAll;
});
const oddChoice = computed(() => {
  return proxy.$parent.oddChoice;
});
const openDetailSheet = computed(() => {
  return proxy.$parent.openDetailSheet;
});
const editSingleProductAttribute = computed(() => {
  return proxy.$parent.editSingleProductAttribute;
});
const openAdditionalService = computed(() => {
  return proxy.$parent.openAdditionalService;
});
//------------------------ pageLoad -------------------------------------
//------------------------ methods -------------------------------------
defineExpose({});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
input[type="checkbox"] {
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
  border: 1px solid #dfdfdf;
  border-radius: 6px;
}
.cartCon {
  padding: 20px 30px;

  .shopsBox {
    border-radius: 6px;
    display: flex;
    flex-direction: column;

    .onlygoodsBox {
      padding: 30px;
      background-color: white;
      margin-bottom: 15px;
      border-radius: 6px;
    }

    h2 {
      display: flex;
      height: 40px;
      align-items: center;
      margin-bottom: 25px;

      input[type="checkbox"] {
        margin-top: 0;
        margin-right: 13px;
      }

      img {
        width: 32px;
        height: 30px;
        margin-right: 7px;
      }

      span {
        font-size: 28px;
        line-height: 1;
      }
    }

    .goodsBox {
      display: flex;

      input[type="checkbox"] {
        margin-top: 6px;
        margin-right: 13px;
      }

      .productPurchaseInfo {
        flex: 1;
        max-width: 622px;

        .productInfo {
          display: flex;
          margin-bottom: 18px;

          .productImage {
            flex: 0 0 180px;
            height: 180px;
            margin-right: 24px;
            border-radius: 6px;
          }

          .goodsSmalInfo {
          }

          h3 {
            margin-top: -4px;
            font-size: 28px;
            line-height: 32px;
            margin-bottom: 15px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            .icon1688 {
              width: 76px;
              height: 30px;
              background: #ff4000;
              border-radius: 2px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              margin-right: 5px;
              img {
                width: 52px;
              }
            }
          }

          .productAttribute {
            width: 350px;
            height: 42px;
            background: #ffffff;
            border: 1px solid #dfdfdf;
            border-radius: 6px;
            display: flex;
            align-items: center;
            padding: 0 12px;
            margin-bottom: 18px;

            p {
              line-height: 42px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 24px;

              span {
                font-size: 24px;
              }
            }

            .arrow-down {
              width: 16px;
              height: 9.5px;
              line-height: 1;
              font-weight: 700;
              color: #333333;
              margin-left: auto;
            }
          }

          .quantityAndAmount {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            .amount {
              font-size: 24px;
              color: #b4272b;

              b {
                font-size: 28px;
                font-weight: 600;
              }
            }

            .van-stepper,
            :deep(.van-stepper) {
              width: 146px;

              button {
                flex: 0 0 40px;
                height: 40px;
              }

              input {
                flex: 0 0 60px;
                width: 60px;
                height: 40px;
                margin: 0 3px;
              }
            }
          }
        }

        .additionalService {
          height: 42px;
          background: #ffffff;
          border: 1px solid #dfdfdf;
          border-radius: 6px;
          margin-bottom: 12px;
          padding: 8px 10px;
          display: flex;
          align-items: center;
          line-height: 1;

          p {
            font-size: 24px;
            line-height: 1;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          span {
            line-height: 42px;
            font-size: 24px;
          }

          .tag {
            position: relative;
            top: -3px;
          }
        }

        .customerNote {
          width: 100%;
          height: 42px;
          background: #ffffff;
          border: 1px solid #dfdfdf;
          border-radius: 6px;
          font-size: 24px;
          line-height: 40px;
          padding: 0px 12px;

          &::placeholder {
            color: #c6c6c6;
          }
        }
      }
    }
  }
}
</style>
