<!-- 订单详情其他状态商品列表模块 -->
<template>
  <div>
    <div class="cartCon">
      <div class="shopsBox" v-for="(item, index) in lists" :key="index">
        <div
          class="onlygoodsBox"
          v-for="(listItem, listIndex) in item.goods"
          :key="listIndex"
        >
          <h2>
            <img :src="require('@/assets/icon/dingdanfanhao.png')" alt="" />
            <span>番号：#{{ listItem.sort }}</span>
          </h2>
          <div class="goodsBox">
            <input
              v-if="
                $parent.orderStatusNum >= 40 || $parent.orderStatusNum == -2
              "
              type="checkbox"
              v-model="listItem.checked"
              @change="oddChoice(index)"
            />
            <div class="productPurchaseInfo">
              <div class="productInfo">
                <!-- 2023/7/20 业务员反应需要点击图片跳转到对应商品链接 -->
                <!-- 截图: https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202307/64b8fbc8ad97c.png -->
                <van-image
                  lazy-load
                  class="productImage"
                  :src="listItem.pic"
                  @click="
                    $fun.routerToPage(
                      `ProductDetails?goods_id=${listItem.goods_id}&fromPlatform=${listItem.from_platform}`
                    )
                  "
                >
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
                <div class="goodsSmalInfo">
                  <h3
                    @click="
                      $fun.toCommodityDetails(
                        listItem.goods_id,
                        listItem.from_platform
                      )
                    "
                  >
                    <span
                      class="icon1688"
                      v-if="listItem.from_platform == '1688'"
                    >
                      <img
                        :src="require('@/assets/icon/icon1688.png')"
                        alt=""
                      />
                    </span>
                    {{ listItem.goods_title }}
                  </h3>
                  <div class="detailBox">
                    <div
                      class="productAttribute"
                      @click="
                        $parent.getProductSpecificationData({
                          item: listItem,
                          omethods: () => {
                            openDetailSheet(listItem, true);
                          },
                        })
                      "
                    >
                      <p>
                        <span
                          v-for="(detailItem, detailIndex) in listItem.detail"
                          :key="detailIndex"
                        >
                          {{ detailItem.value }};
                        </span>
                      </p>

                      <img
                        class="arrow-down"
                        :src="require('@/assets/icon/arrow-downskdjfh.png')"
                        alt=""
                      />
                    </div>
                    <div class="productQuantity flexAllCenter">
                      x{{ listItem.num }}
                    </div>
                  </div>

                  <div
                    class="quantityAndAmount"
                    :class="{ onRight: $parent.orderStatusNum >= 30 }"
                  >
                    <div class="amount">
                      <b>
                        {{
                          $fun.RMBNumSegmentation(
                            listItem.univalence
                              ? listItem.univalence
                              : Number(listItem.price)
                          )
                        }}元</b
                      >(
                      {{
                        $fun.JPYNumSegmentation(
                          $fun.roundNumber(
                            (listItem.univalence
                              ? listItem.univalence
                              : Number(listItem.price)) * $parent.exchange_rate
                          )
                        )
                      }}円)
                    </div>
                  </div>
                </div>
              </div>
              <!-- 国内运费 -->
              <div class="goodsProceInfo" v-if="$parent.orderStatusNum >= 30">
                <div class="title">{{ $fanyi("中国运费") }}：</div>
                <div class="priceBox">
                  <span
                    >{{
                      $fun.RMBNumSegmentation(Number(listItem.freight || 0))
                    }}元</span
                  >
                  <span
                    >({{
                      $fun.RMBNumSegmentation(
                        $fun.roundNumber(
                          Number(listItem.freight || 0) * $parent.exchange_rate
                        )
                      )
                    }}円)</span
                  >
                </div>
              </div>
              <!-- 小计 -->
              <div class="goodsProceInfo" v-if="$parent.orderStatusNum >= 30">
                <div class="title">{{ $fanyi("小计") }}：</div>
                <div class="priceBox">
                  <span
                    >{{
                      $fun.RMBNumSegmentation(
                        $fun.ceil(
                          Number(listItem.freight || 0) + (listItem.total || 0)
                        )
                      )
                    }}元</span
                  >
                  <span
                    >({{
                      $fun.JPYNumSegmentation(
                        $fun.roundNumber(
                          (Number(listItem.freight || 0) +
                            (listItem.total || 0)) *
                            $parent.exchange_rate
                        )
                      )
                    }}円)</span
                  >
                </div>
              </div>
              <!-- option -->
              <div
                class="additionalService"
                @click="openOption(listItem)"
                v-if="
                  listItem.option &&
                  listItem.option.find((item) => {
                    return item.checked == true && item.num > 0;
                  })
                "
              >
                <p>
                  <span
                    v-for="optionItem in listItem.option"
                    :key="optionItem.id"
                    v-show="optionItem.checked && optionItem.num > 0"
                  >
                    <span class="tag">{{ optionItem.sort }}</span> *{{
                      optionItem.num
                    }}；
                  </span>
                </p>
              </div>
              <div class="remarkTitle" v-if="listItem.client_remark">
                {{ $fanyi("客户备注") }}
              </div>
              <!-- 客户备注 -->
              <textarea
                class="customerNote readOnly"
                v-autoHeightForLineBreak
                v-if="listItem.client_remark"
                v-model="listItem.client_remark"
                @change="editSingleProductAttribute(listItem)"
              ></textarea>
              <div class="remarkTitle" v-if="listItem.y_reply">
                {{ $fanyi("业务回复") }}
              </div>
              <!-- 业务回复 -->
              <div class="businessReply" v-if="listItem.y_reply">
                {{ listItem.y_reply }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, computed } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
const lists = computed(() => {
  return proxy.$parent.lists;
});
const userOperationMode = computed(() => {
  return proxy.$parent.userOperationMode;
});
const shopAll = computed(() => {
  return proxy.$parent.shopAll;
});
const oddChoice = computed(() => {
  return proxy.$parent.oddChoice;
});
const openDetailSheet = computed(() => {
  return proxy.$parent.openDetailSheet;
});
const editSingleProductAttribute = computed(() => {
  return proxy.$parent.editSingleProductAttribute;
});
const openAdditionalService = computed(() => {
  return proxy.$parent.openAdditionalService;
});
const orderStatusNum = computed(() => {
  return proxy.$parent.orderStatusNum;
});
//------------------------ pageLoad -------------------------------------
//------------------------ methods --------------------------------------
// 去除用户没有选中的附加服务并显示
const openOption = (listItem) => {
  let arr = JSON.parse(JSON.stringify(listItem));
  for (let i = 0; i < arr.option.length; i++) {
    const element = arr.option[i];
    if (!element.checked) {
      arr.option.splice(i, 1);
      i--;
    }
  }
  openAdditionalService.value(arr, true);
};
defineExpose({});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.cartCon {
  padding: 20px 30px;

  .shopsBox {
    border-radius: 6px;
    display: flex;
    flex-direction: column;

    .onlygoodsBox {
      padding: 30px;
      background-color: white;
      margin-bottom: 15px;
      border-radius: 6px;
    }

    h2 {
      display: flex;
      height: 40px;
      align-items: center;
      margin-bottom: 25px;

      input[type="checkbox"] {
        margin-top: 0;
        margin-right: 13px;
      }

      img {
        width: 32px;
        height: 30px;
        margin-right: 7px;
      }

      span {
        font-size: 28px;
        line-height: 1;
      }
    }

    .goodsBox {
      display: flex;

      input[type="checkbox"] {
        margin-top: 6px;
        margin-right: 13px;
      }

      .productPurchaseInfo {
        flex: 1;
        max-width: 622px;

        .productInfo {
          display: flex;
          margin-bottom: 23px;

          .productImage {
            flex: 0 0 180px;
            height: 180px;
            margin-right: 24px;
            border-radius: 6px;
          }

          .goodsSmalInfo {
          }

          h3 {
            margin-top: -4px;
            font-size: 28px;
            line-height: 32px;
            margin-bottom: 15px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            .icon1688 {
              width: 76px;
              height: 30px;
              background: #ff4000;
              border-radius: 2px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              margin-right: 5px;
              img {
                width: 52px;
              }
            }
          }
          .detailBox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 33px;
            .productAttribute {
              width: 310px;
              height: 42px;
              background: #f2f2f2;
              border-radius: 6px;
              display: flex;
              align-items: center;
              padding: 0 12px;

              p {
                line-height: 42px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 24px;

                span {
                  font-size: 24px;
                  color: #999;
                }
              }

              .arrow-down {
                width: 16px;
                height: 9.5px;
                line-height: 1;
                font-weight: 700;
                color: #333333;
                margin-left: auto;
              }
            }
            .productQuantity {
              min-width: 60px;
              padding: 0 10px;
              height: 42px;
              background: #f2f2f2;
              border-radius: 6px;
              font-size: 20px;
            }
          }

          .quantityAndAmount {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            .amount {
              font-size: 24px;
              color: #b4272b;

              b {
                font-size: 28px;
                font-weight: 600;
              }
            }

            .van-stepper,
            :deep(.van-stepper) {
              width: 146px;

              button {
                flex: 0 0 40px;
                height: 40px;
              }

              input {
                flex: 0 0 60px;
                width: 60px;
                height: 40px;
                margin: 0 3px;
              }
            }
          }
          .quantityAndAmount.onRight {
            justify-content: flex-end;
          }
        }
        .goodsProceInfo {
          display: flex;
          align-items: center;
          margin-bottom: 23px;
          * {
            font-size: 24px;
            line-height: 1;
          }
          .title {
          }
          .priceBox {
            color: #b4272b;
          }
        }
        .additionalService {
          height: 42px;
          background: #ffffff;
          border: 1px solid #dfdfdf;
          border-radius: 6px;
          margin-bottom: 12px;
          padding: 8px 10px;
          display: flex;
          align-items: center;
          line-height: 1;

          p {
            font-size: 24px;
            line-height: 1;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          span {
            line-height: 42px;
            font-size: 24px;
          }

          .tag {
            width: 24px;
            height: 24px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            border: solid 1px;
            border-radius: 50%;
          }
        }
        .remarkTitle {
          font-size: 24px;
          line-height: 1.3;
          margin-bottom: 10px;
        }
        .customerNote {
          width: 100%;
          height: 42px;
          background: #ffffff;
          border: 1px solid #dfdfdf;
          border-radius: 6px;
          font-size: 24px;
          line-height: 40px;
          padding: 0px 12px;

          &::placeholder {
            color: #c6c6c6;
          }
        }
        .businessReply {
          width: 630px;
          margin-top: 10px;
          min-height: 42px;
          padding: 10px 12px;
          background: #eff4ff;
          border: 1px solid #2755b4;
          border-radius: 6px;
          font-size: 24px;
          line-height: 1.2;
          font-weight: 400;
          color: #000000;
        }
      }
    }
  }
}
</style>
