<!-- 订单详细数据弹窗 -->
<template>
  <van-action-sheet
    v-model:show="show"
    :title="$fanyi('订单明细')"
    class="orderDetailPopup"
  >
    <div class="modelBox">
      <van-collapse v-model="activeNames" class="dataBox">
        <div class="dataTitle noDown">
          <span class="dataName">{{ $fanyi("商品金额") }}</span>
          <span>
            {{
              $fun.RMBNumSegmentation(otherPriceData.goods_amount_rmb) ||
              $fun.RMBNumSegmentation(
                $fun.ceil(orderNumData.totalItemPurchasePrice)
              )
            }}元（{{
              $fun.JPYNumSegmentation(otherPriceData.goods_amount_jpy) ||
              $fun.JPYNumSegmentation(
                $fun.roundNumber(
                  orderNumData.totalItemPurchasePrice * $parent.exchange_rate
                )
              )
            }}円）</span
          >
        </div>

        <van-collapse-item name="1">
          <template #title>
            <div class="dataTitle">
              <span class="dataName">{{ $fanyi("手续费") }}</span>
              <span
                >{{
                  $fun.RMBNumSegmentation(otherPriceData.service_amount_rmb) ||
                  $fun.RMBNumSegmentation(
                    $fun.ceil(orderNumData.allTransactionFees)
                  )
                }}元（{{
                  $fun.JPYNumSegmentation(otherPriceData.service_amount_jpy) ||
                  $fun.JPYNumSegmentation(
                    $fun.roundNumber(
                      orderNumData.allTransactionFees * $parent.exchange_rate
                    )
                  )
                }}円）</span
              >
              <button>{{ $fanyi("明细") }}</button>
            </div>
          </template>
          <div class="productListBox">
            <div
              class="productListBoxOpt"
              v-for="(productDataItem, productDataIndex) in productData"
              :key="productDataIndex"
            >
              <div
                class="onlygoodsBox"
                v-for="(listItem, listIndex) in productDataItem.goods"
                :key="listIndex"
              >
                <h4>
                  <img
                    :src="require('@/assets/icon/dingdanfanhao.png')"
                    alt=""
                  />
                  <span>{{ $fanyi("番号") }}：#{{ listItem.sort }}</span>
                </h4>
                <div class="goodsBox">
                  <div class="productPurchaseInfo">
                    <div class="productInfo">
                      <van-image
                        lazy-load
                        class="productImage"
                        :src="listItem.pic"
                        @click="$lookImg(listItem.pic)"
                      >
                        <template v-slot:loading>
                          <van-loading type="spinner" size="20" />
                        </template>
                      </van-image>
                      <div class="goodsSmalInfo">
                        <h3
                          @click="
                            $fun.toCommodityDetails(
                              listItem.goods_id,
                              listItem.from_platform
                            )
                          "
                        >
                          {{ listItem.goods_title }}
                        </h3>
                        <div class="productAttribute">
                          <p class="goodsName">
                            <span
                              v-for="(
                                detailItem, detailIndex
                              ) in listItem.detail"
                              :key="detailIndex"
                            >
                              {{ detailItem.value }};
                            </span>
                          </p>

                          <p class="goodsNum">x{{ listItem.num }}</p>
                        </div>
                        <div class="quantityAndAmount">
                          <div>
                            <span class="titleBox">
                              {{ $fanyi("单价") }}：
                            </span>
                            <span class="amount">
                              {{
                                $fun.RMBNumSegmentation(
                                  listItem.univalence
                                    ? listItem.univalence
                                    : Number(listItem.price)
                                )
                              }}元({{
                                $fun.JPYNumSegmentation(
                                  $fun.roundNumber(
                                    (listItem.univalence
                                      ? listItem.univalence
                                      : Number(listItem.price)) *
                                      $parent.exchange_rate
                                  )
                                )
                              }}円)
                            </span>
                          </div>
                          <div></div>
                        </div>
                        <div class="quantityAndAmount">
                          <div>
                            <span class="titleBox">
                              {{ $fanyi("手续费") }}：
                            </span>
                            <span class="amount">
                              {{
                                $parent.hasUsedCoupon
                                  ? 0
                                  : $fun.RMBNumSegmentation(
                                      $fun.ceil(
                                        listItem.service_rate * listItem.total
                                      )
                                    )
                              }}元({{
                                $parent.hasUsedCoupon
                                  ? 0
                                  : $fun.JPYNumSegmentation(
                                      $fun.roundNumber(
                                        listItem.service_rate *
                                          listItem.total *
                                          $parent.exchange_rate
                                      )
                                    )
                              }}円)</span
                            >
                          </div>
                          <div class="transactionFeeRatio">
                            {{ $fanyi("手续费比例") }}：{{
                              $fun.ceil(
                                $parent.hasUsedCoupon
                                  ? 0
                                  : listItem.service_rate * 100
                              )
                            }}%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-collapse-item>
        <div
          class="dataTitle noDown"
          v-if="!['临时保存待提出'].includes($parent.orderStatus)"
        >
          <span class="dataName">{{ $fanyi("国内运费") }}</span>
          <span
            >{{
              $fun.RMBNumSegmentation(
                otherPriceData.china_logistics_price_rmb
              ) ||
              $fun.RMBNumSegmentation(
                $fun.ceil(orderNumData.entireOrderShippingFee || 0)
              )
            }}元（{{
              $fun.JPYNumSegmentation(
                otherPriceData.china_logistics_price_jpy
              ) ||
              $fun.JPYNumSegmentation(
                $fun.roundNumber(
                  (orderNumData.entireOrderShippingFee || 0) *
                    $parent.exchange_rate
                )
              )
            }}円）</span
          >
        </div>
        <van-collapse-item name="2">
          <template #title>
            <div class="dataTitle">
              <span class="dataName">{{ $fanyi("附加服务费") }}</span>
              <span
                >{{
                  $fun.RMBNumSegmentation(otherPriceData.option_amount_rmb) ||
                  $fun.RMBNumSegmentation(
                    $fun.ceil(orderNumData.allAdditionalServiceFees)
                  )
                }}元（{{
                  $fun.JPYNumSegmentation(otherPriceData.option_amount_jpy) ||
                  $fun.JPYNumSegmentation(
                    $fun.roundNumber(
                      orderNumData.allAdditionalServiceFees *
                        $parent.exchange_rate
                    )
                  )
                }}円）</span
              >
              <button>{{ $fanyi("明细") }}</button>
            </div>
          </template>
          <div class="additionalServiceList">
            <div
              class="additionalServiceOpt"
              v-for="(
                additionalServiceItem, additionalServiceIndex
              ) in orderNumData.allSelectedAdditionalServices"
              :key="additionalServiceItem.id"
            >
              <ul class="additionalServiceInfo">
                <li>
                  <span
                    class="aname"
                    @click="
                      additionalServiceItem.checked =
                        !additionalServiceItem.checked;
                      calculateAdditionalServicePrice();
                    "
                    @change="calculateAdditionalServicePrice"
                  >
                    {{ additionalServiceIndex + 1 }}）
                    {{ additionalServiceItem.name_translate }}
                  </span>
                  <span class="anum">
                    {{ $fanyi("数量") }}：{{ additionalServiceItem.num }}
                  </span>
                </li>
                <li>
                  <span class="aprice"> </span>
                  <span class="atotal">
                    {{ $fanyi("小计") }}：<span class="totalNum">
                      {{ additionalServiceItem.total }}元({{
                        $fun.JPYNumSegmentation(
                          $fun.roundNumber(
                            additionalServiceItem.total * $parent.exchange_rate
                          )
                        )
                      }}円)
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </van-collapse-item>
        <div class="dataTitle noDown">
          <span class="dataName">{{ $fanyi("其他费用") }}</span>
          <span
            >{{
              $fun.RMBNumSegmentation(otherPriceData.other_fee_rmb) ||
              $fun.RMBNumSegmentation(
                $fun.ceil($parent.allDetails.other_price || 0)
              )
            }}元（{{
              $fun.JPYNumSegmentation(otherPriceData.other_fee_jpy) ||
              $fun.JPYNumSegmentation(
                $fun.roundNumber(
                  ($parent.allDetails.other_price || 0) * $parent.exchange_rate
                )
              )
            }}円）</span
          >
        </div>
        <div class="dataTitle noDown">
          <span class="dataName">{{
            $parent.orderStatusNum >= 40
              ? $fanyi("付款时汇率")
              : $fanyi("当前汇率")
          }}</span>
          <span>1元 = {{ $parent.exchange_rate }}円</span>
        </div>
        <div class="dataTitle noDown borTop">
          <span class="dataName">{{ $fanyi("物流选择") }}</span>
          <div class="wuliuxuanze">
            <van-dropdown-menu
              direction="up"
              class="xialakuang"
              v-if="['临时保存待提出'].includes($parent.orderStatus)"
            >
              <van-dropdown-item
                v-model="$parent.logistics_id"
                :options="shippingMethodList"
              />
            </van-dropdown-menu>
            <div v-else class="logisticsSelection">
              {{ $parent.logistics.name }}
            </div>
          </div>
        </div>
        <div class="dataTitle noDown borTop">
          <span class="dataName">{{ $fanyi("订单备注") }}</span>
          <textarea
            :class="{
              readOnly: !['临时保存待提出'].includes($parent.orderStatus),
            }"
            v-model="$parent.client_remark"
            :placeholder="
              orderStatus == '临时保存待提出'
                ? $fanyi(
                    '* 对该订单有特殊要求的，可以在这边备注 \n* 备注后会同步显示给业务员'
                  )
                : ''
            "
          ></textarea>
        </div>
      </van-collapse>
      <userActionBar
        :isInOrderDetailPopup="true"
        v-if="
          ['临时保存待提出'].includes($parent.orderStatus) &&
          !$parent.orderModification
        "
      />
    </div>
  </van-action-sheet>
</template>
<script setup>
import { getCurrentInstance, ref, computed } from "vue";
import userActionBar from "./userActionBar";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
const show = ref(false);
const activeNames = ref([]);
const orderNumData = computed(() => {
  return proxy.$parent.orderNumData;
});
const userOperationMode = computed(() => {
  return proxy.$parent.userOperationMode;
});
const delCart = computed(() => {
  return proxy.$parent.delCart;
});
const allChoice = computed(() => {
  return proxy.$parent.allChoice;
});
const jieSuan = computed(() => {
  return proxy.$parent.allChoice;
});
const totalPrice = computed(() => {
  return proxy.$parent.totalPrice;
});
const goodsTotal = computed(() => {
  return proxy.$parent.goodsTotal;
});
const selectedItemQuantity = computed(() => {
  return proxy.$parent.selectedItemQuantity;
});
const productData = computed(() => {
  return proxy.$parent.lists;
});
const shippingMethodList = computed(() => {
  return proxy.$parent.shippingMethodList;
});
const createOrder = computed(() => {
  return proxy.$parent.createOrder;
});
const orderStatus = computed(() => {
  return proxy.$parent.orderStatus;
});
// 接口返回数据
const otherPriceData = computed(() => {
  return proxy.$parent.otherPriceData || {};
});
//------------------------ pageLoad -------------------------------------
//------------------------ methods -------------------------------------
const open = (data, methods) => {
  show.value = true;
};

defineExpose({
  open,
  show,
  selectedItemQuantity,
  goodsTotal,
  orderNumData,
  totalPrice,
  jieSuan,
  allChoice,
  delCart,
  userOperationMode,
  createOrder,
  orderStatus,
  exchange_rate: proxy.$parent.exchange_rate,
});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.priceFOnt {
  font-weight: 600;
  color: #b4272b;
}

.modelBox {
  padding: 0 0px 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  *::after {
    border: none;
  }
  .productListBox {
    min-height: 288px;
    background-color: #f9f9f9;

    padding: 0 30px;

    .productListBoxOpt {
      .onlygoodsBox {
        padding: 30px 0;
        margin: 0 35px;
        border-bottom: solid 1px #dfdfdf;
      }

      h4 {
        display: flex;
        height: 40px;
        align-items: center;
        margin-bottom: 25px;

        input[type="checkbox"] {
          margin-top: 0;
          margin-right: 13px;
        }

        img {
          width: 32px;
          height: 30px;
          margin-right: 7px;
        }

        span {
          font-size: 28px;
          line-height: 1;
          color: #000000;
        }
      }

      .productPurchaseInfo {
        flex: 1;
        max-width: 622px;

        .productInfo {
          display: flex;
          margin-bottom: 18px;

          .productImage {
            flex: 0 0 180px;
            height: 180px;
            margin-right: 12px;
            border-radius: 6px;
          }

          .goodsSmalInfo {
            color: #000000;
          }

          h3 {
            margin-top: -4px;
            font-size: 28px;
            line-height: 32px;
            margin-bottom: 15px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          .productAttribute {
            height: 42px;
            display: flex;
            align-items: center;
            margin-bottom: 18px;

            p {
              background: #f2f2f2;
              line-height: 42px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 24px;
              border-radius: 6px;
              padding: 0 12px;

              span {
                font-size: 24px;
                color: black;
              }
            }

            .goodsName {
              width: 310px;
              margin-right: auto;
            }

            .goodsNum {
              min-width: 60px;
              padding: 0 15px;
              text-align: center;
            }

            .arrow-down {
              width: 16px;
              height: 9.5px;
              line-height: 1;
              font-weight: 700;
              color: #333333;
              margin-left: auto;
            }
          }

          .quantityAndAmount {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            span {
              font-size: 24px;
            }

            .titleBox {
              display: inline-block;
              width: 103px;
            }

            .transactionFeeRatio {
              font-size: 20px;
              color: #999999;
            }

            .amount {
              font-size: 24px;
              color: #b4272b;
            }

            .van-stepper,
            :deep(.van-stepper) {
              width: 146px;

              button {
                flex: 0 0 40px;
                height: 40px;
              }

              input {
                flex: 0 0 60px;
                width: 60px;
                height: 40px;
                margin: 0 3px;
              }
            }
          }
        }

        .additionalService {
          height: 42px;
          background: #ffffff;
          border: 1px solid #dfdfdf;
          border-radius: 6px;
          margin-bottom: 12px;
          padding: 8px 10px;
          display: flex;
          align-items: center;
          line-height: 1;

          p {
            font-size: 24px;
            line-height: 1;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          span {
            line-height: 42px;
            font-size: 24px;
          }

          .tag {
            width: 24px;
            height: 24px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            border: solid 1px;
            border-radius: 50%;
          }
        }

        .customerNote {
          width: 100%;
          height: 42px;
          background: #ffffff;
          border: 1px solid #dfdfdf;
          border-radius: 6px;
          font-size: 24px;
          line-height: 40px;
          padding: 0px 12px;

          &::placeholder {
            color: #c6c6c6;
          }
        }
      }
    }
  }

  :deep(.van-collapse-item__content) {
    padding: 0;
  }

  :deep(.van-collapse-item__title) {
    padding: 0 30px !important;
  }

  .dataBox,
  :deep(.dataBox) {
    .van-collapse-item__title {
      padding: 0;
      display: flex;
      align-items: center;
      .van-icon-arrow {
        font-size: 12px;
        color: black;
      }
    }
  }

  .van-icon-arrow {
    font-size: 25px;
    margin-left: 10px;
  }

  .noDown {
    margin: 0 30px;
  }

  .borTop {
    border-top: 1px solid #dfdfdf;
  }

  .wuliuxuanze {
    .van-dropdown-menu,
    :deep(.van-dropdown-menu) {
      width: 290px !important;

      .van-dropdown-menu__bar {
        height: 42px;
        box-shadow: none;
        border: 1px solid #dfdfdf;
        border-radius: 6px;

        .van-dropdown-menu__title {
          width: 100%;

          .van-ellipsis {
            font-size: 24px;
            // color: #b4272b;
          }

          &:after {
            right: 13px;
            color: #b4272b;
          }
        }

        .van-dropdown-menu__title--active {
          color: #000000;
        }
      }
    }
  }
  .logisticsSelection {
    width: 290px;
    height: 42px;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    color: #010101;
    font-size: 24px;
    display: flex;
    align-items: center;
    padding: 0 12px;
  }
  .dataTitle {
    min-height: 70px;
    padding-top: 25px;
    padding-bottom: 25px;

    display: flex;
    align-items: center;

    span {
      font-size: 24px;
      color: #000000;
    }

    span.dataName {
      width: 200px;
      color: #999999;
      display: inline-block;
    }

    .shippingMethodCheckbox {
      width: 290px;
      height: 42px;
      background: #ffffff;
      border: 1px solid #dfdfdf;
      border-radius: 6px;
      padding: 0 12px;
    }

    button {
      margin-left: auto;
      font-size: 24px;
      color: #b4272b;
      background-color: transparent;
    }
  }

  textarea {
    flex: 1;
    height: 120px;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    padding: 10px;
    font-size: 24px;
    line-height: 32px;
    color: #000000;

    &::placeholder {
      color: #c6c6c6;
    }
  }
}

.additionalServiceList {
  background: #f9f9f9;
  padding: 0 30px;
  min-height: 162px;

  .additionalServiceOpt {
    padding: 30px 0;
    display: flex;
    align-items: flex-start;
    border-top: #dfdfdf solid 1px;

    .additionalServiceInfo {
      padding: 0 25px 0 45px;
      flex: 1;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:first-child {
          margin-bottom: 29px;
        }

        span {
          font-size: 24px;
          color: black;
        }

        .aname {
          font-weight: 600;
        }

        .anum {
          min-width: 220px;
        }

        .aprice {
          .priNum {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 80px;
            height: 42px;
            background: #f2f2f2;
            border-radius: 6px;
            margin-right: 3px;
          }
        }

        .atotal {
          min-width: 220px;

          .totalNum {
            font-weight: 600;
            color: #b4272b;
          }
        }
      }
    }
  }
}

.selectShippingMethodBox {
  padding-top: 40px;
  padding-bottom: 240px;

  li {
    font-size: 24px;
    padding: 20px 30px;
    text-align: center;

    &:active {
      background-color: #f7f8fa;
      color: #b4272b;
    }
  }
}
</style>
<style lang="scss">
.orderDetailPopup {
  .van-action-sheet__header {
    text-align: center !important;
    font-size: 30px;
    .van-action-sheet__close {
      font-size: 25px;
    }
  }
}

.xialakuang {
  .van-cell__title {
    span {
      font-size: 28px;
    }
  }

  .van-dropdown-item__option--active {
    .van-cell__title {
      span {
        font-size: 28px;
        color: #b4272b;
      }
    }

    .van-dropdown-item__icon {
      color: #b4272b;
    }
  }
}
</style>
