<!-- 订单详情用户操作栏模块 -->
<template>
  <div class="cartBottom">
    <!-- 操作栏 -->
    <div class="requestOrderStatus" v-if="userOperationMode == 'propose'">
      <div
        class="openOrderDetailModal"
        v-if="
          $parent.$refs.orderDetailModalRef &&
          !$parent.$refs.orderDetailModalRef.show &&
          !isInOrderDetailPopup
        "
        @click="$parent.openOrderDetailModal([])"
      >
        <span>{{ $fanyi("订单明细") }}</span>
        <img src="../../../assets/icon/arrow-downskdjfh.png" alt="" />
      </div>
      <div class="itemDataGroup">
        <div class="itemData">
          <span class="tit">{{ $fanyi("sku") }}：</span>
          <span>{{ orderNumData.allItemQuantity }}</span>
        </div>
        <div class="itemData totalNumBox">
          <span class="tit">{{ $fanyi("总计") }}：</span>
          <span
            class="priceFont"
            v-if="['已取消'].includes($parent.orderStatus) && isPay"
          >
            <span
              >{{ $fun.RMBNumSegmentation(order_amount.pay_amount) }}元</span
            >
            <span
              >({{
                $fun.JPYNumSegmentation(order_amount.pay_amount_jpy)
              }}円)</span
            >
          </span>
          <span class="priceFont" v-else>
            <span
              >{{
                $fun.RMBNumSegmentation(otherPriceData.sum_amount_rmb) ||
                $fun.RMBNumSegmentation(
                  $fun.ceil(orderNumData.entireOrderAmount)
                )
              }}元</span
            >
            <span
              >({{
                $fun.RMBNumSegmentation(otherPriceData.sum_amount_jpy) ||
                $fun.JPYNumSegmentation(
                  $fun.roundNumber(
                    orderNumData.entireOrderAmount * exchangeRate
                  )
                )
              }}円)</span
            >
          </span>
        </div>
        <div class="itemData">
          <span class="tit">{{ $fanyi("商品总数") }}：</span>
          <span>{{ orderNumData.itemPurchaseQuantity }}</span>
        </div>
        <div class="itemData">
          <span class="tit">{{ $fanyi("预计费用") }}：</span>
          <span>
            {{ allDetails.order_amount?.predict_logistics_price_rmb }} 元（{{
              $fun.JPYNumSegmentation(
                allDetails.order_amount?.predict_logistics_price_jpy
              )
            }}
            円）</span
          >
        </div>
        <div class="itemData"></div>
        <div class="itemData currentExchangeRate">
          <span class="tit">{{ $fanyi("当前汇率") }}：</span>
          <span class="grayFont">1元={{ exchangeRate }}円</span>
        </div>
      </div>
      <div
        class="buttonGroup"
        v-if="['临时保存待提出'].includes(orderStatus) && !orderModification"
      >
        <button class="temporarySave" @click="createOrder('tempSave')">
          {{ $fanyi("一時保存") }}
        </button>
        <button @click="createOrder('submit')">
          {{ $fanyi("提交订单") }}
        </button>
      </div>
      <div
        class="buttonGroup"
        v-if="['临时保存待提出'].includes(orderStatus) && orderModification"
      >
        <button class="temporarySave" @click="unmodify">
          {{ $fanyi("取消修改") }}
        </button>
        <button @click="reintroduce">
          {{ $fanyi("再次提出") }}
        </button>
      </div>
      <div
        class="buttonGroup"
        v-else-if="['等待付款'].includes(orderStatus) && part_pay_status < 1"
      >
        <div class="useCoupon">
          <p>
            <input
              v-if="$parent.couponList && $parent.couponList.length"
              type="checkbox"
              :checked="$parent.hasUsedCoupon"
              @click="$parent.openUseCouponModal"
            />
            <span
              v-if="$parent.couponList && $parent.couponList.length"
              @click="$parent.openUseCouponModal"
              >{{ $fanyi("使用优惠券") }}</span
            >
          </p>
          <p v-if="$parent.hasUsedCoupon">
            <span class="promptText">{{ $fanyi("已使用免手续费券") }}</span>
          </p>
        </div>
        <button @click="$parent.proceedToPayment">
          {{ $fanyi("立即付款") }}
        </button>
      </div>
      <!-- 等待付款之后的订单需要可以再来一单 -->
      <!-- 2023/8/31  已取消的订单也可以再次提出-->

      <div
        class="buttonGroup"
        v-else-if="$parent.orderStatusNum >= 40 || $parent.orderStatusNum == -2"
      >
        <input
          type="checkbox"
          name=""
          id=""
          v-model="$parent.checked"
          @change="$parent.allChoice"
        />
        <span
          class="quanXuan"
          @click="
            $parent.checked = !$parent.checked;
            $parent.allChoice();
          "
          >{{ $fanyi("全选") }}</span
        >
        <button style="margin-left: auto" @click="goexcelOrderPreview">
          {{ $fanyi("再次订购") }}
        </button>
      </div>
    </div>
    <!-- 删除操作栏 -->
    <div class="con" v-else>
      <input
        type="checkbox"
        name=""
        id=""
        v-model="$parent.checked"
        @change="$parent.allChoice"
      />
      <span
        class="quanXuan"
        @click="
          $parent.checked = !$parent.checked;
          $parent.allChoice();
        "
        >{{ $fanyi("全选") }}</span
      >
      <button @click="delCart()">
        {{ $fanyi("删除") }}
      </button>
    </div>
    <!-- <div v-if="!['临时保存待提出', '报价中', '等待付款'].includes(orderStatus) && 使用优惠券">
      fsjdahf
    </div> -->
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, computed, inject } from "vue";
const { proxy } = getCurrentInstance();

//------------------------ data -------------------------------------
const allDetails = inject('allDetails');
const props = defineProps({
  // 这个是否是订单明细弹窗里引用的, true=是
  isInOrderDetailPopup: {
    default: false,
  },
});
//datas.part_pay_status == 1 待支付定金 part_pay_status==3 待支付尾款
const part_pay_status = computed(() => {
  return proxy.$parent.allDetails.part_pay_status || 0;
});
//是否是等待付款状态修改订单,配合orderStatus一起使用
const order_amount = computed(() => {
  return proxy.$parent.allDetails.order_amount || {};
});
//是否是等待付款状态修改订单,配合orderStatus一起使用
const orderModification = computed(() => {
  return proxy.$parent.orderModification;
});
const reintroduce = computed(() => {
  return proxy.$parent.reintroduce;
});
const unmodify = computed(() => {
  return proxy.$parent.unmodify;
});
const orderStatus = computed(() => {
  return (
    proxy.$parent.orderStatus ||
    proxy.$parent.$parent.$parent.$parent.orderStatus
  );
});
const isInOrderDetailPopup = ref(props.isInOrderDetailPopup);
const userOperationMode = computed(() => {
  return (
    proxy.$parent.userOperationMode ||
    proxy.$parent.$parent.$parent.$parent.userOperationMode
  );
});
const delCart = computed(() => {
  return proxy.$parent.delCart || proxy.$parent.$parent.$parent.$parent.delCart;
});
const isPay = computed(() => {
  return proxy.$parent.isPay;
});
const allChoice = computed(() => {
  return (
    proxy.$parent.allChoice || proxy.$parent.$parent.$parent.$parent.allChoice
  );
});
const goexcelOrderPreview = () => {
  proxy.$parent.proposeAgain();
  // proxy.$router.push({
  //   path: "/excelOrderPreview",
  //   query: {
  //     ...proxy.$route.query,
  //     isProposedAgain: true,
  //   },
  // });
};
const totalPrice = computed(() => {
  return (
    proxy.$parent.totalPrice || proxy.$parent.$parent.$parent.$parent.totalPrice
  );
});
const goodsTotal = computed(() => {
  return (
    proxy.$parent.goodsTotal || proxy.$parent.$parent.$parent.$parent.goodsTotal
  );
});
const createOrder = computed(() => {
  return (
    proxy.$parent.createOrder ||
    proxy.$parent.$parent.$parent.$parent.createOrder
  );
});
const selectedItemQuantity = computed(() => {
  return (
    proxy.$parent.selectedItemQuantity ||
    proxy.$parent.$parent.$parent.$parent.selectedItemQuantity
  );
});
const orderNumData = computed(() => {
  return (
    proxy.$parent.orderNumData ||
    proxy.$parent.$parent.$parent.$parent.orderNumData
  );
});


// 接口返回数据
const otherPriceData = computed(() => {
  return proxy.$parent.otherPriceData || {};
});
const exchangeRate = computed(() => {
  return (
    proxy.$parent.exchange_rate ||
    proxy.$parent.$parent.$parent.$parent.exchange_rate
  );
});

//------------------------ pageLoad -------------------------------------
//------------------------ methods -------------------------------------
defineExpose({});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.cartBottom {
  margin-top: auto;
  position: sticky;
  bottom: 119px;
  .dianzi {
  }
  .con {
    background: #ffffff;
    border: #dfdfdf solid 1px;
    border-left: none;
    border-right: none;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    span {
      display: inline-block;
      font-size: 24px;
      line-height: 1;
    }
    input[type="checkbox"] {
      margin: 0;
      flex: 0 0 24px;
      height: 24px;
      margin-right: 6px;
    }
    .quanXuan {
      margin-right: 30px;
    }
    .numBox {
      * {
        font-size: 24px;
      }
      .fanshu {
        width: 120px;
        margin-bottom: 11px;
      }
    }

    button {
      margin-left: auto;
      width: 150px;
      height: 70px;
      background: #b4272b;
      border-radius: 6px;
      font-size: 28px;
      line-height: 1;
      color: #ffffff;
    }
  }
  .requestOrderStatus {
    background-color: white;
    .openOrderDetailModal {
      width: 750px;
      height: 58px;
      background: #f9f9f9;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 65px;
      border-top: #dfdfdf 1px solid;
      border-bottom: #dfdfdf 1px solid;
      span {
        color: #b4272b;
        font-size: 24px;
      }
      img {
        width: 18px;
        height: 12px;
        rotate: 180deg;
      }
    }
    .itemDataGroup {
      padding-left: 65px;
      padding-top: 16px;
      padding-bottom: 5px;
      display: flex;
      flex-wrap: wrap;
      .itemData {
        width: 340px;
        font-size: 24px;
        line-height: 40px;
        span {
          font-size: 24px;
        }
        span.tit {
          display: inline-block;
          //   width: 120px;
        }
      }
      .itemData.currentExchangeRate {
        span {
          font-size: 20px;
        }
        .grayFont {
          color: #666;
        }
      }
      .itemData.totalNumBox {
        display: flex;
        .priceFont {
          flex: 1;
          color: #b4272b;
          font-weight: 600;

          span {
            white-space: nowrap;
          }
        }
      }
    }
    .buttonGroup {
      padding: 10px 65px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .useCoupon {
        min-height: 65px;
        flex: 1;
        input[type="checkbox"] {
          flex: 0 0 24px;
          height: 24px;
          border: 1px solid #dfdfdf;
          border-radius: 6px;
          margin-left: 0;
          margin-right: 11px;
        }
        p {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
          span {
            font-size: 24px;
          }
          .promptText {
            margin-left: 35px;
            color: #b4272b;
          }
        }
      }
      span {
        display: inline-block;
        font-size: 24px;
        line-height: 1;
      }
      button {
        width: 280px;
        height: 70px;
        background: #b4272b;
        border-radius: 6px;
        font-size: 27px;
        font-weight: 400;
        color: #ffffff;
      }
      button.temporarySave {
        background: #27b470;
        white-space: nowrap;
      }
    }
  }
}
</style>
