<!-- 订单详情优惠券使用弹窗模块 -->
<template>
  <div>
    <van-action-sheet
      v-model:show="show"
      :title="$fanyi('选择优惠券')"
      class="couponUsageModal"
    >
      <div class="couponList">
        <div
          class="singleCouponP"
          v-for="(singleCouponPItem, singleCouponPIndex) in couponList"
          :key="singleCouponPIndex"
          @click="selectingCoupons(singleCouponPItem, singleCouponPIndex)"
          :class="{ activeCoupon: activeCoupon == singleCouponPIndex }"
        >
          <!-- 被用户选中的标志 -->
          <div class="selected">
            <img :src="require('@/assets/icon/gouBai.png')" alt="" />
          </div>
          <div class="couponInfo">
            <div class="other">
              <div class="couponType">
                {{ $fanyi(singleCouponPItem.type_name) }}
              </div>
              <div class="couponCode">NO.{{ singleCouponPItem.sn }}</div>
            </div>
            <div class="couponName">
              {{
                singleCouponPItem.type_name == "优惠券"
                  ? $fanyi("免手续费")
                  : singleCouponPItem.name_translation
              }}
            </div>
          </div>
          <div class="couponValidity">
            <span
              >{{ $fanyi("有效期") }}：{{
                getCurrentDayFGF({
                  time: singleCouponPItem.effective_date,
                  fgf: ".",
                })
              }}
              -
              {{
                getCurrentDayFGF({
                  time: singleCouponPItem.expiry_date,
                  fgf: ".",
                })
              }}</span
            >
            <span class="couponScope flexAllCenter">注文書</span>
          </div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted } from "vue";
import { getCurrentDayFGF } from "@/utlis/date.js";
const { proxy } = getCurrentInstance();
//------------------------ data -----------------------------------------

const show = ref(false);
const couponList = ref([]);
const activeCoupon = ref(-1);
//------------------------ pageLoad -------------------------------------
// 获取优惠券列表
const getCouponList = () => {
  proxy.$api
    .orderUsableDiscount({
      pageSize: 999,
      page: 1,
      order_sn: proxy.$route.query.order_sn,
    })
    .then((res) => {
      ////console.log('事件名',res)
      if (res.code != 0) return;
      //接下来的操作
      couponList.value = res.data.data;
      proxy.$parent.couponList = res.data.data;
    });
};
//------------------------ methods --------------------------------------
// 选择优惠券
const selectingCoupons = (item, i) => {
  if (activeCoupon.value == i) {
    activeCoupon.value = -1;
    proxy.$parent.hasUsedCoupon = false;
  } else {
    activeCoupon.value = i;
    proxy.$parent.hasUsedCoupon = true;
    // 赋值已使用优惠券数据
    proxy.$parent.usedCouponInfo = item;
    // 重新计算
  }
  proxy.$parent.commodityTotal();
};
onMounted(() => {
  getCouponList();
});
const open = () => {
  show.value = true;
};
defineExpose({ open });
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.couponList {
  min-height: 700px;
  padding: 0 30px 120px;

  .singleCouponP {
    width: 690px;
    height: 200px;
    background: #ffffff;
    border: 1px solid #b4272d;
    border-radius: 20px;
    margin-bottom: 15px;
    padding: 35px;
    position: relative;

    .couponInfo {
      display: flex;
      padding-bottom: 25px;
      border-bottom: 3px dashed #dfdfdf;
      align-items: flex-start;
      margin-bottom: 20px;

      .other {
        width: 297px;
        margin-right: 25px;

        .couponType {
          font-size: 32px;
          font-weight: bold;
          color: #010101;
          line-height: 1;
          margin-bottom: 15px;
        }

        .couponCode {
          font-size: 24px;
          font-weight: 400;
          color: #666666;
        }
      }

      .couponName {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 48px;
        font-weight: bold;
        color: #b4272d;
      }
    }

    .selected {
      right: 0;
      top: 0;
      position: absolute;
      display: none;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      background: #b4272d;
      border-radius: 0px 20px 0px 20px;

      img {
        width: 19px;
        height: 14px;
      }
    }

    .couponValidity {
      display: flex;
      align-items: center;

      span {
        font-size: 24px;
        color: #999999;
      }

      .couponScope {
        margin-left: 24px;
        width: 80px;
        height: 32px;
        background: #ffe9e9;
        border-radius: 6px;
        font-size: 20px;
        color: #b4272d;
      }
    }
  }

  .singleCouponP.activeCoupon {
    .selected {
      display: flex;
    }
  }
}
</style>
<style lang="scss">
.couponUsageModal {
  .van-action-sheet__header {
    text-align: center !important;
    font-size: 30px;
  }
}
</style>
